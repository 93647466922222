import { createContext, useReducer } from "react";

export const StartCardsContext = createContext();

export const startcardsReducer = (state, action) => {
  switch (action.type) {
    case "SET_START_CARDS":
      return {
        startcards: action.payload,
      };

    case "UPDATE_START_CARDS":
      return {
        startcards: [...state.startcards],
      };

    default:
      return state;
  }
};

export const StartCardsContextProvider = ({ children }) => {
  const [state, scdispatch] = useReducer(startcardsReducer, {
    startcards: null,
  });

  return (
    <StartCardsContext.Provider value={{ ...state, scdispatch }}>
      {children}
    </StartCardsContext.Provider>
  );
};
