import { useState, useEffect, useContext } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
// import FolderIcon from "@mui/icons-material/Folder";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import AddCircleIcon from "@mui/icons-material/AddCircle";

import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import FormControl from "@mui/material/FormControl";
import ResponsiveAppBar from "./ResponsiveAppBar";

import { LoginContext } from "../context/LoginContext";

export default function Category() {
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const { token } = useContext(LoginContext);

  const form = useForm({
    defaultValues: {
      category: "",
      description: "",
    },
  });
  const { register, handleSubmit, formState, getValues } = form;
  const { errors } = formState;

  const [open, setOpen] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isAdding, setIsAdding] = useState(null); //defaults to Adding
  const [index, setIndex] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      try {
        await axios.get(`/api/sc/category/${token.projectId}`).then((data) => {
          // await axios.get(`/api/sc/category/`).then((data) => {
          //added '/${token.projectId}': 09-Oct-2024
          console.log(data.data);
          setCategories(data.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getCategories(); //This is the right way to call a useEffect function --> React gives a warning if this is not the case
  }, []);

  //this useEffect's purpose is just to refresh the screen when the delete or save button is click
  // useEffect(() => {
  //   // console.log(categories);
  // }, [categories]);

  const onSubmit = async (data) => {
    let newData = {
      ...data,
      projectId: token.projectId, //added: 09-Oct-2024
    };
    if (isAdding) {
      try {
        await axios.post("/api/sc/category", newData).then((data) => {
          // console.log("server response: ", data);
          setCategories([...categories, data.data.data]);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      //Editing here
      if (index >= 0) {
        const id = categories[index]._id;
        try {
          await axios.post(`/api/sc/category/${id}`, data).then((data) => {
            // console.log("server response: ", data);

            const nextCategories = categories.map((c, i) => {
              if (i === index) {
                // Replace the edited element
                c.category = getValues("category");
                c.description = getValues("description");
                return c;
              } else {
                // The rest haven't changed
                return c;
              }
            });
            setCategories(nextCategories);
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    setIsAdding(null);
  };

  const handleClose = () => {
    navigate("/dashboard", { replace: true });
    setOpen(false);
  };

  //all taken out for the time being to avoid inadvertently editing the categories (Golden Rules): 28-11-2024
  // const handleDelete = async (idx) => {
  //   setCategories(
  //     categories.filter((c) => {
  //       return c._id !== categories[idx]._id;
  //     })
  //   );
  //   if (categories[idx]._id) {
  //     try {
  //       await axios
  //         .delete(`api/sc/category/${categories[idx]._id}`)
  //         .then((d) => {
  //           // console.log(d);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     // console.log(categories[idx], idx);
  //   }
  // };
  // const handleEdit = async (idx) => {
  //   setIsAdding(false);
  //   setIndex(idx);
  //   setValue("category", categories[idx].category);
  //   setValue("description", categories[idx].description);
  // };

  // const handleAdd = () => {
  //   setIsAdding(true);
  // };

  return (
    <div>
      <ResponsiveAppBar />
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography
            sx={{ fontWeight: "bold", textAlign: "center" }}
            variant="h4"
          >
            category updates
          </Typography>
        </DialogTitle>
        {categories && (
          <Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row">
                <Typography
                  sx={{ margin: 2, fontWeight: "bold" }}
                  variant="h6"
                  component="div"
                >
                  Category List
                </Typography>
                {/* <AddCircleIcon
                  sx={{
                    marginTop: "20px",
                    color: "green",
                    fontSize: "30px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={handleAdd}
                /> */}
              </Stack>
              <List dense={false}>
                {categories.map((category, idx) => {
                  return (
                    <>
                      <ListItem
                        key={idx}
                        // secondaryAction={
                        //   <div>
                        //     <IconButton aria-label="edit">
                        //       <EditIcon onClick={() => handleEdit(idx)} />
                        //     </IconButton>
                        //     <IconButton aria-label="delete">
                        //       <DeleteIcon onClick={() => handleDelete(idx)} />
                        //     </IconButton>
                        //   </div>
                        // }
                      >
                        <ListItemText>{category.category}</ListItemText>
                      </ListItem>
                    </>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        )}
        {isAdding !== null && (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Typography variant="h6" sx={{ margin: 2, fontWeight: "bold" }}>
              {isAdding ? "Adding new category" : "Editing a category"}
            </Typography>
            <DialogContent>
              <TextField
                name="category"
                label="Category"
                type="text"
                {...register("category", {
                  required: "Category is required",
                })}
                error={!!errors.category}
                helperText={errors.category?.message}
                fullWidth
              />
            </DialogContent>

            <DialogContent>
              <TextField
                name="description"
                label="Description"
                {...register("description")}
                error={!!errors.description}
                helperText={errors.description?.message}
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
}
