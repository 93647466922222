import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEmployersContext } from "../hooks/useEmployersContext";

import axios from "axios";
import moment from "moment";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import Stack from "@mui/material/Stack";
// import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";

import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ResponsiveAppBar from "./ResponsiveAppBar";
import { FormInputDropdown } from "./form-components/FormInputDropdown";

import ListStartCard from "./ListStartCard";
import Loading from "./Loading";

export default function ArchivedStartCards() {
  const { projectId } = useParams();
  const { employers, dispatch } = useEmployersContext(); //now using 'context' to solve issues on updating actions on the employee list: 10-Sept-2024
  // console.log("Project ID: ", projectId);
  useForm({
    defaultValues: {
      employer: "",
      workLocation: "",
    },
  });
  const { handleSubmit, control, setValue } = useForm();

  const [open, setOpen] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [startcards, setStartCards] = useState([]);
  const [filteredsc, setFilteredsc] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sdate, setSdate] = useState(dayjs(new Date()));
  const [edate, setEdate] = useState(dayjs(new Date()));

  const navigate = useNavigate();

  useEffect(() => {
    const getAllStartCards = async () => {
      // console.log("pID: ", projectId);
      setIsLoading(true);
      await axios.get(`/api/sc/getallstartcards/${projectId}`).then((sc) => {
        // console.log("sc: ", sc);
        setStartCards(sc.data);
        setFilteredsc(sc.data);
        setIsLoading(false);
        setOpen(true);
      });
    };

    const getEmployers = async () => {
      const employers = await axios.get(`/api/sc/getemployers/${projectId}`);
      // localStorage.setItem("employers", JSON.stringify(employers.data));

      const employersData = employers.data.map((employer) => {
        return {
          value: employer.id,
          label: employer.name,
        };
      });

      //sort Employers by name
      employersData.sort((a, b) => {
        let fa = a.label.toLowerCase(),
          fb = b.label.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      dispatch({ type: "SET_EMPLOYERS", payload: employersData }); //using 'context' to manage these data on employers: 19-Sept-2024
    };

    const getLocations = async () => {
      await axios.get(`/api/sc/locations/${projectId}`).then((response) => {
        const locationOptions = response.data.map((l) => {
          return { label: l.location, value: l.location };
        });
        setLocationList(locationOptions);
      });
    };

    getAllStartCards();
    getEmployers();
    getLocations();

    // setIsLoading(false);
  }, []);

  const handleClose = () => {
    navigate("/dashboard", { replace: true });
    setOpen(false);
  };

  const handleCloseFilter = () => {
    setFilterOpen(false);
  };
  //Reset filter
  const handleResetFilter = async () => {
    setIsLoading(true);
    // setFilterOpen(false);
    // setFilteredsc(startcards); //restore original START cards
    //to make sure you get the latest uploads of START cards
    await axios.get(`/api/sc/getallstartcards/${projectId}`).then((sc) => {
      // console.log("sc: ", sc);
      setStartCards(sc.data);
      setFilteredsc(sc.data);
      setIsLoading(false);
    });
    setValue("employer", "");
    setValue("workLocation", "");
  };
  const handleClick = () => {
    setClicked(true);
    setFilterOpen(true);
  };

  const onSubmit = async (d) => {
    // console.log("d: ", d);
    // console.log("sdate: ", sdate);
    // console.log("edate: ", edate);
    const newsc = startcards.filter((sc) => {
      if (
        (d.employer === "" || d.employer === undefined) &&
        (d.workLocation === "" || d.workLocation === undefined)
      ) {
        // console.log("no employer and location");
        return (
          moment(sc.workDate).format("YYYY-MM-DD").trim() >=
            moment(sdate.$d).format("YYYY-MM-DD").trim() &&
          moment(sc.workDate).format("YYYY-MM-DD").trim() <=
            moment(edate.$d).format("YYYY-MM-DD").trim()
        );
      } else if (
        (d.workLocation === "" || d.workLocation === undefined) &&
        d.employer.length > 0
      ) {
        // console.log("no location, with employer");
        return (
          sc.employerId === d.employer &&
          moment(sc.workDate).format("YYYY-MM-DD").trim() >=
            moment(sdate.$d).format("YYYY-MM-DD").trim() &&
          moment(sc.workDate).format("YYYY-MM-DD").trim() <=
            moment(edate.$d).format("YYYY-MM-DD").trim()
        );
      } else if (
        (d.employer === "" || d.employer === undefined) &&
        d.workLocation.length > 0
      ) {
        // console.log("no employer with location");
        return (
          sc.location.toUpperCase() === d.workLocation.toUpperCase() &&
          moment(sc.workDate).format("YYYY-MM-DD").trim() >=
            moment(sdate.$d).format("YYYY-MM-DD").trim() &&
          moment(sc.workDate).format("YYYY-MM-DD").trim() <=
            moment(edate.$d).format("YYYY-MM-DD").trim()
        );
      } else {
        // console.log("full!");
        return (
          sc.employerId === d.employer &&
          sc.location.toUpperCase() === d.workLocation.toUpperCase() &&
          moment(sc.workDate).format("YYYY-MM-DD").trim() >=
            moment(sdate.$d).format("YYYY-MM-DD").trim() &&
          moment(sc.workDate).format("YYYY-MM-DD").trim() <=
            moment(edate.$d).format("YYYY-MM-DD").trim()
        );
      }
    });

    setFilteredsc(newsc);
    setFilterOpen(false);
  };

  return (
    <div>
      <ResponsiveAppBar />
      {isLoading && <Loading isLoading={isLoading} message="Loading..." />}
      {filteredsc && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="xxl"
          sx={{ margin: 3 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", textAlign: "center", margin: 2 }}
            >
              START Cards
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                margin: 2,
                p: 2,
                backgroundColor: "blue",
                color: "white",
              }}
            >
              {filteredsc.length}
            </Typography>

            <Button
              variant="contained"
              sx={{ margin: 2, padding: 1 }}
              onClick={handleClick}
            >
              <Typography>Filter</Typography>
              <FilterListIcon sx={{ marginLeft: 2 }} />
            </Button>
          </Box>
          {clicked && (
            <Dialog
              open={filterOpen}
              onClose={handleCloseFilter}
              fullWidth
              maxWidth="md"
            >
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", fontWeight: "bold", marginTop: 1 }}
                >
                  Filter
                </Typography>

                <Stack direction="row">
                  {employers && (
                    <DialogContent sx={{ margin: 1, padding: 1 }}>
                      <FormInputDropdown
                        name="employer"
                        control={control}
                        label="Employer"
                        options={employers}
                      />
                    </DialogContent>
                  )}

                  {locationList && (
                    <DialogContent sx={{ margin: 1, padding: 1 }}>
                      <FormInputDropdown
                        name="workLocation"
                        control={control}
                        label="Work Location"
                        options={locationList}
                      />
                    </DialogContent>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    margin: 1,
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={sdate}
                      onChange={(newValue) => {
                        // console.log(newValue);
                        setSdate(newValue);
                      }}
                    />
                    <DatePicker
                      label="End Date"
                      value={edate}
                      onChange={(newValue) => setEdate(newValue)}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ justifyContent: "end", margin: 2 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleResetFilter}
                  >
                    Reset
                  </Button>
                </Stack>
              </form>
            </Dialog>
          )}

          {/* {filteredsc && <ListStartCard data={filteredsc} />} */}
          <ListStartCard data={filteredsc} />
        </Dialog>
      )}
    </div>
  );
}
