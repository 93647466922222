import { useState, useEffect, useContext, forwardRef } from "react";
import { useNavigate } from "react-router-dom";

// import { LoginContext } from "../context/LoginContext";
import { useLoginContext } from "../hooks/useLoginContext";

import axios from "axios";

import { useForm } from "react-hook-form";

import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { FormInputDropdown } from "./form-components/FormInputDropdown";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login({ socket }) {
  // const { token, setToken } = useContext(LoginContext);
  const { dispatch } = useLoginContext();
  // const { dispatch } = useAuthContext();

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      projectId: "",
    },
  });
  // console.log("token: ", token);

  const { register, handleSubmit, formState, setError, control } = form;
  const { errors } = formState;

  const navigate = useNavigate();

  // const { handleSubmit, reset, control, setValue } = useForm();
  const [open, setOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);
  const [msg, setMsg] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [version, setVersion] = useState(null);

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log("fullscreen: ", fullScreen);

  useEffect(() => {
    socket.emit("getversion");
    socket.on("loginmsg", (data) => {
      setVersion(data);
      console.log("version: ", data);
    });
  }, []);
  //runs at the start of this component
  useEffect(() => {
    const getProjects = async () => {
      const projects = await axios.get("/api/user/getprojects");
      // console.log("Projects: ", projects.data);
      // localStorage.setItem("projects", JSON.stringify(projects.data));

      try {
        const projectsData = projects.data[0].map((project) => {
          return {
            value: project.projectId,
            label: project.name,
          };
        });
        //sort projects by name
        projectsData.sort((a, b) => {
          let fa = a.label.toLowerCase(),
            fb = b.label.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });

        setProjects(projectsData);
        // setVersion(projects.data[1]);
      } catch (err) {
        console.log(err);
      }
    };

    getProjects();
  }, []);

  const handleClose = () => {
    // navigate("/", { replace: true });
    setOpenMsg(false);
    // setOpen(false);
    // setIsEditing(true);
    // setIsAdding(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data) => {
    // console.log("data: ", data);
    if (data.projectId) {
      try {
        setIsLoading(true);
        const response = await axios.post("/api/user/login", data, {
          withCredentials: true,
        });
        // console.log("login user: ", response.data);
        const project = projects.filter((p) => {
          return p.value === response.data.projectId;
          // return p.value === response.data.user.projectId;
        });
        // console.log(project);
        response.data.project = project[0];
        // SET_LOGIN;
        dispatch({ type: "SET_LOGIN", payload: response.data }); //using 'context' to manage login token data: 27-Oct-2024
        // dispatch({ type: "LOGIN", payload: response.data });
        // setToken(response.data); //updates the variables from the context
        socket.emit("login", { message: `successful login: ${data.email}` }); //in App.js
        setIsLoading(false);
        // localStorage.setItem("user", JSON.stringify(response.data));
        // localStorage.setItem("projectId", data.projectId);

        navigate(`/dashboard`, { replace: true });

        setOpen(false); //closes the login form
      } catch (error) {
        console.log("error!", error.message);

        //Set the error message manually for the useForm hook
        setError("password", {
          type: "manual",
          message: "Wrong username or password",
        });
      }
    } else {
      setOpenMsg(true); //show the Snackbar message
      setMsg("Please select project");
      setSeverity("error");
      // navigate("/");
    }
  };

  // email, password, business_unit, project, entity, cmo_user;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openMsg}
        onClose={handleClose}
        message="Hotwork closed-out"
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
      {projects ? (
        <Container maxWidth="sm">
          <Dialog open={open} sx={{ margin: 0, padding: 0 }}>
            <Box>
              <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                    start card
                  </Typography>
                  <Typography variant="caption">version {version}</Typography>
                </Box>
                <Typography sx={{ fontSize: 14 }}>
                  use Hammertech login
                </Typography>
              </DialogTitle>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogContent sx={{ margin: 2, padding: 1 }}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      name="email"
                      label="email"
                      type="email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Not a valid email",
                        },
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      required
                    />
                  </FormControl>
                </DialogContent>
                <DialogContent sx={{ margin: 2, padding: 1 }}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="outlined-adornment-password"
                      name="password"
                      label="password"
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Password is required",
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </DialogContent>
                <DialogContent sx={{ margin: 2, padding: 1 }}>
                  <FormInputDropdown
                    name="projectId"
                    control={control}
                    label="Project"
                    options={projects}
                    // setChoice={setChoice}
                  />
                </DialogContent>
                <DialogContent sx={{ margin: 2, padding: 1 }}>
                  <DialogActions>
                    {isLoading && <CircularProgress />}
                    <Button type="submit" variant="contained" color="primary">
                      Login
                    </Button>
                  </DialogActions>
                </DialogContent>
              </form>
            </Box>
            <Typography
              variant="caption"
              sx={{
                padding: "2px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}
            >
              developed with <VolunteerActivismIcon /> by gilberto gabon
            </Typography>
          </Dialog>
        </Container>
      ) : (
        "Loading projects..."
      )}
    </>
  );
}
