import { Chart, defaults } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";

import Container from "@mui/material/Container";
// import Card from "@mui/material/Card";

Chart.register(CategoryScale);
// const options = {
//   plugins: {
//     colors: {
//       forceOverride: true,
//     },
//   },
// };

defaults.responsive = true;
defaults.maintainAspectRatio = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "center";
defaults.plugins.title.color = "black";
defaults.plugins.title.font.size = 20;
// defaults.plugins.colors.color = "black";
const PieChartByLocation = ({ data }) => {
  return (
    <Container maxWidth="xl">
      <Container>
        <Doughnut
          data={data}
          options={{
            plugins: {
              title: {
                text: "By Location",
              },
              colors: {
                forceOverride: true,
              },
            },
          }}
        />
      </Container>
    </Container>
  );
};
export default PieChartByLocation;
