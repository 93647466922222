import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, Typography } from "@mui/material";

function Loading({ isLoading, message }) {
  const [open] = useState(isLoading);

  return (
    <Dialog open={open} fullWidth>
      <Container sx={{ padding: 5, display: "flex", justifyContent: "center" }}>
        <CircularProgress sx={{ mx: 3 }} />
        <Typography sx={{ textAlign: "center" }}>{message}</Typography>
      </Container>
    </Dialog>
  );
}
export default Loading;
