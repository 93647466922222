import { Chart, defaults } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";

import Container from "@mui/material/Container";
// import Card from "@mui/material/Card";

Chart.register(CategoryScale);

defaults.responsive = true;
defaults.maintainAspectRatio = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "center";
defaults.plugins.title.color = "black";
defaults.plugins.title.font.size = 20;

const LineChartByMonth = ({ data }) => {
  // console.log(data.length);
  return (
    <>
      <Container maxWidth="xl">
        <div style={{ width: "100%", height: "20%" }}>
          <Line
            data={data}
            options={{
              elements: {
                line: {
                  tension: 0.5,
                },
              },
              plugins: {
                title: {
                  text: "By Month",
                },
              },
              // animations: {
              //   tension: {
              //     duration: 1000,
              //     easing: "linear",
              //     from: 1,
              //     to: 0,
              //     loop: false,
              //   },
              // },
            }}
          />
        </div>
      </Container>
    </>
  );
};
export default LineChartByMonth;
