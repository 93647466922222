import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";

//main display of all GR buttons - this is called from GoldenRules.js component
const GoldenRuleButton = ({ handleClick, goldenRule, showMore, submitted }) => {
  useEffect(() => {
    // console.log("submitted: ", submitted);
  }, [submitted]);
  return (
    <>
      {goldenRule?.map((g, idx) => {
        return (
          <Grid item md={showMore ? 12 : 4} xs={12}>
            <Button
              sx={{
                padding: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
              disabled={g.disabled}
              variant={g.variant}
              color="primary"
              onClick={() => handleClick(g.clickParam, idx)}
              fullWidth
            >
              <img
                src={g.imgSrc}
                width="100px"
                height="100px"
                alt="golden rule icon"
              />
              <Typography
                sx={{
                  margin: 2,
                  // color: g.variant === "outlined" ? "black" : "white",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {g.clickParam}
              </Typography>
            </Button>
          </Grid>
        );
      })}
    </>
  );
};

export default GoldenRuleButton;
