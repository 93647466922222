import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import { useAttendeesContext } from "../hooks/useAttendeesContext";
import { Typography } from "@mui/material";

function SignaturePad({ setOnSign, setImgUrl, setDoneSign, idx, name }) {
  const [sign, setSign] = useState();
  const [url, setUrl] = useState();
  const [open, setOpen] = useState(true);
  const [errMsg, setErrMsg] = useState(null);

  const { employees, dispatch } = useAttendeesContext();

  const handleClear = () => {
    sign.clear();
    setUrl("");
    // setOnSign(false);
    // setOpen(false);
  };
  const handleGenerate = () => {
    // console.log("sign: ", sign);
    // console.log("isEmpty: ", sign.isEmpty());
    if (sign.isEmpty()) {
      setErrMsg("Please sign.");
      return false;
    }
    setErrMsg(null);
    setUrl(sign.getTrimmedCanvas().toDataURL("image/png"));
    setOnSign(false);
    setDoneSign(true);
    setOpen(false);
    const id = employees[idx].id;
    dispatch({
      type: "UPDATE_ATTENDEES",
      payload: (employees[idx] = {
        id,
        name,
        idx,
        imgUrl: sign.getTrimmedCanvas().toDataURL("image/png"),
        isSigned: true,
      }),
    });
  };

  return (
    <Dialog open={open} onClose={handleClear} fullWidth>
      <DialogTitle>{"Signature"}</DialogTitle>
      <SignatureCanvas
        canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        minDistance={0}
        ref={(data) => setSign(data)}
      />

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClear}>
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerate}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
      <img src={url} />
      {errMsg && (
        <Typography sx={{ textAlign: "center", color: "red" }}>
          {errMsg}
        </Typography>
      )}
    </Dialog>
  );
}
export default SignaturePad;
