// import { createContext, useContext, useState } from "react";
import io from "socket.io-client";

// import { LoginContext } from "./context/LoginContext";

import { Routes, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import Home from "./components/Home";
import StartCard from "./components/StartCard";
import Category from "./components/Category";
import Location from "./components/Location";
import HazardControl from "./components/HazardControl";
import StartCardUrl from "./components/StartCardUrl";
import ArchivedStartCards from "./components/ArchivedStartCards";
import ProjectSetup from "./components/ProjectSetup";
import GenerateProjectQRCode from "./components/GenerateProjectQRCode";

import Login from "./components/Login";
// import LogOut from "./components/LogOut";
import NotFound from "./components/NotFound";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Dashboard from "./components/Dashboard";

const socket = io.connect("/"); //this is enough to open the socket connection

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: `"Poppins","Roboto","Helvetica","Arial",sans-serif`,
      textTransform: "none",
    },
  },
});

export default function App() {
  // const [token, setToken] = useState(null);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          {/* <Route path="/" element={<StartCard socket={socket} />} /> */}
          <Route path="/:projectId" element={<StartCard socket={socket} />} />
          <Route path="/login" element={<Login socket={socket} />} />
          <Route path="/getsc/:id" element={<StartCardUrl />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard" element={<Home socket={socket} />} />
            {/* <Route path="/dashboard" element={<Dashboard socket={socket} />} /> */}
            <Route path="/category" element={<Category />} />
            <Route path="/location" element={<Location />} />
            <Route path="/hazcontrol" element={<HazardControl />} />
            <Route path="/project" element={<ProjectSetup />} />
            <Route path="/projectqrcode" element={<GenerateProjectQRCode />} />
            <Route
              path="/archive/:projectId"
              element={<ArchivedStartCards />}
            />
            {/* <Route path="/logout" element={<LogOut />} /> */}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}
