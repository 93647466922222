import React, { useState, useEffect } from "react";

import axios from "axios";

import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { FormInputMultiCheckboxCustom } from "./form-components/FormInputMultiCheckboxCustom";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

import GoldenRuleButton from "./GoldenRuleButton";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const GoldenRules = ({
  setValue,
  getValues,
  control,
  register,
  submitted,
  setCategoryId,
  projectId,
  categoryList,
  setCategoryList,
  errors,
  isConfirmed,
  setIsConfirmed,
  controls,
  setControls,
}) => {
  //categories or the Golden Rules
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");

  //These contain the data from the DB - these are first updated during the useEffect() of this component
  const [directs, setDirects] = useState([]);
  const [indirects, setIndirects] = useState([]);
  const [behaviour, setBehaviour] = useState([]);
  const [details, setDetails] = useState([]);

  //This is a switch to show the list of controls
  const [showDetails, setShowDetails] = useState(false);

  //These are options for the checkboxes
  const [directControlList, setDirectControlList] = useState([]);
  const [indirectControlList, setIndirectControlList] = useState([]);
  const [behaviourControlList, setBehaviourControlList] = useState([]);

  //These are trackers for the contents of the CheckBoxes of the controls
  const [directControls, setDirectControls] = useState([]);
  const [indirectControls, setIndirectControls] = useState([]);
  const [behaviourControls, setBehaviourControls] = useState([]);
  // const [allControls, setAllControls] = useState([]);

  //tracker for the description of the current category/Golden Rule
  const [description, setDescription] = useState("");

  //to show the ShowMore button
  const [showMore, setShowMore] = useState(false);
  // const [isConfirmed, setIsConfirmed] = useState(false);

  //The index of the currently selected category/Golden Rule
  const [index, setIndex] = useState(null);

  //Tracker array for the 'disabled' buttons of the goldenRule array variable defined at the start of this component
  // const [arrDisabled, setArrDisabled] = useState([]); //not used anymore

  //Tracker array of what has been selected (pressed) Golden Rule button
  const [arrSelected, setArrSelected] = useState([]);

  const [errMsg, setErrMsg] = useState(null);

  const [goldenRule, setGoldenRule] = useState([
    {
      clickParam: "01-HEIGHTS & DROPPED OBJECTS",
      imgSrc: "SGR-icon-fallinObject.png",
      disabled: submitted,
      variant: "outlined",
    },

    {
      clickParam: "02-PEOPLE & PLANT",
      imgSrc: "SGR-icon-peopleplant.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "03-ROADS & RAIL",
      imgSrc: "SGR-icon-railroad.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "04-LIFTING OPERATIONS",
      imgSrc: "SGR-icon-lifting.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "05-TEMPORARY WORKS",
      imgSrc: "SGR-icon-temp.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "06-LIQUID BODIES",
      imgSrc: "SGR-icon-water.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "07-ENERGY ISOLATION",
      imgSrc: "SGR-icon-energiso.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "08-ELECTRICAL EQUIPMENT",
      imgSrc: "SGR-icon-elec.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "09-EXCAVATION",
      imgSrc: "SGR-icon-excav.png",
      disabled: submitted,
      variant: "outlined",
    },
    {
      clickParam: "10-CONFINED SPACE",
      imgSrc: "SGR-icon-confined.png",
      display: "flex",
      visibility: "visible",
      disabled: submitted,
      variant: "outlined",
    },
  ]);

  useEffect(() => {
    const getCategories = async () => {
      await axios.get(`/api/sc/category/${projectId}`).then((response) => {
        setCategories(response.data);
        // console.log("categories: ", response.data);
      });
    };
    // const getAllHazards = async () => {
    //   await axios.get(`/api/sc/allhazards/${projectId}`).then((response) => {
    //     setHazards(response.data);
    //   });
    // };

    const getAllDirects = async () => {
      await axios.get(`/api/sc/alldirects/${projectId}`).then((response) => {
        // console.log("directs: ", response.data);
        setDirects(response.data);
      });
    };

    const getAllIndirects = async () => {
      await axios.get(`/api/sc/allindirects/${projectId}`).then((response) => {
        setIndirects(response.data);
      });
    };

    const getAllBehaviours = async () => {
      try {
        await axios.get(`/api/sc/allbehaviours/${projectId}`).then((data) => {
          // console.log(data.data);
          setBehaviour(data.data);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const getAllDetails = async () => {
      try {
        await axios.get(`/api/sc/alldetails/${projectId}`).then((data) => {
          // console.log(data.data);
          setDetails(data.data);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getCategories();
    // getAllHazards();
    getAllDirects();
    getAllIndirects();
    getAllBehaviours();
    getAllDetails();
  }, [projectId]);

  useEffect(() => {
    // console.log("refresh the GoldenRule.js component");
  }, [showDetails]);

  //Golden Rules button click method - this handles per one button click
  const handleClick = (energy, idx) => {
    //'energy' and 'idx' are passed from the GoldenRuleButton.js component

    //enable the Confirm Button
    setIsConfirmed(false);

    // console.log("button clicked: ", energy, idx);
    setShowDetails(!showDetails); //this causes this component to refresh

    //'goldenRule' is the state variable in this component here (GoldenRules.js)
    if (goldenRule[idx].variant === "outlined") {
      //---------------------------------------------------------------------------
      // When the button was still not selected as denoted by the 'outlined' variant
      //---------------------------------------------------------------------------

      setIndex(idx); //update local state variable 'index'

      const categoryId = categories[idx]._id; //'categories' array is a local state variable in this component here. This is created during initial load of this component using 'useEffect'

      categories[idx].imgSrc = goldenRule[idx].imgSrc; //NOTE: this assumes here that the ORDER of the contents from the Categories DB is the same as the 'goldenRule' state variable

      setCategory(categories[idx].category); //local state variable - this contains only one object - the GR button that was pressed
      // setCategoryList([...categoryList, categories[idx]]);

      setDescription(categories[idx].description); //update this local state variable

      //the state variable that this updates is coming from its parent component 'StartCard.js'
      setCategoryId(categoryId); //will update the categoryId state variable from StartCard component --> 06-Oct-2024

      //retrieve only the 'direct', 'indirect', and 'behaviour' controls from its corresponding DB (loading during useEffect) that belongs to the current GR button that was clicked
      const directsByCategory = directs.filter((c) => {
        return c.categoryId === categoryId;
      });
      const indirectsByCategory = indirects.filter((i) => {
        return i.categoryId === categoryId;
      });

      const behaviourByCategory = behaviour.filter((i) => {
        return i.categoryId === categoryId;
      });

      //create now the 'options' for the various checkboxes under the FormInputMultiCheckboxCustom.js component
      const directControlOptions = directsByCategory.map((control) => {
        return {
          label: control.directControl,

          value: {
            controlId: control._id,
            description: control.directControl,
            ...control, //include the whole object from the DB
          },
        };
      });
      // console.log("directControlOptions: ", directControlOptions);

      const indirectControlOptions = indirectsByCategory.map((control) => {
        return {
          label: control.indirectControl,

          value: {
            controlId: control._id,
            description: control.indirectControl,
            ...control, //include the whole object from the DB
          },
        };
      });
      // console.log("indirectControlOptions: ", indirectControlOptions);

      const behaviourControlOptions = behaviourByCategory.map((control) => {
        return {
          label: control.behaviour,

          value: {
            controlId: control._id,
            description: control.behaviour,
            ...control, //include the whole object from the DB
          },
        };
      });
      // console.log("behaviourControlOptions: ", behaviourControlOptions);

      //now mark the selected Golden Rule as per the clicked one
      goldenRule[idx].variant = "contained"; //change the button background to 'contained' from being 'outlined'
      goldenRule[idx].disabled = true; //disable it - don't allow the clicking of this button which is now only a single button among the previously 10 buttons

      //update these state variables
      setShowMore(true); //to display the 'ShowMore' button - to display back the whole 10-Golden Rules buttons
      setGoldenRule([goldenRule[idx]]); //now the 'goldenRule' state variable (an array) contains only 'one' element - the one that was clicked

      //update these local state variables - these are the ones that goes back to the 'StartCard' component?
      setDirectControlList(directControlOptions); //actually the list of CRITICAL controls
      setIndirectControlList(indirectControlOptions); //actually the list of STANDARD controls
      setBehaviourControlList(behaviourControlOptions); //the list of BEHAVIOUR controls

      //---------------------------------------------------------------------------------------------------
      //Now, during the time when the GR button was already marked and 'was pressed again', do the following:
      //---------------------------------------------------------------------------------------------------
    } else if (goldenRule[idx].variant === "contained") {
      goldenRule[idx].variant = "outlined"; //switch back to "outlined" variant
      setShowDetails(false); //stop showing the controls

      // setCategory(null); //just to refresh
      const newgr = [...goldenRule]; //just to update the 'variant' of the button
      // console.log("newgr: ", newgr);
      setGoldenRule(newgr); //just to update the 'variant' of the button

      //remove this GR from the categoryList which is a state variable from the parent component StartCard.js
      const newCategories = categoryList.filter((c) => {
        return categories[idx]._id !== c._id; //NOTE: 'categories' here is the local state variable of this component which is created during useEffect (retrieve from its DB - Categories)
      });

      //update the state variable from the parent component - StartCard.js
      setCategoryList([...newCategories]);

      //Remove also the index from the 'arrSelected' array local state variable. This is initially updated when the 'Confirm' button is pressed - 'handleButton' method
      const newArrSelected = arrSelected.filter((s) => {
        return s !== index; //get the elements which are not of the same index from the one being removed
      });
      setArrSelected([...newArrSelected]); //update the local state variable - this state variables contain only a list of indeces of pressed (selected) GR buttons

      //update the controls state variables
      let newDirects = [];
      directControls.forEach((d) => {
        const filtered = d.filter((d1) => {
          return d1.categoryId !== categories[idx]._id;
        });

        if (filtered.length > 0) {
          newDirects.push(filtered);
        }
      });

      let newIndirects = [];
      indirectControls.forEach((d) => {
        const filtered = d.filter((d1) => {
          return d1.categoryId !== categories[idx]._id;
        });
        if (filtered.length > 0) {
          newIndirects.push(filtered);
        }
      });
      let newBehaviours = [];
      behaviourControls.forEach((d) => {
        const filtered = d.filter((d1) => {
          return d1.categoryId !== categories[idx]._id;
        });
        if (filtered.length > 0) {
          newBehaviours.push(filtered);
        }
      });

      // console.log("newDirects:", newDirects);
      // console.log("newIndirects:", newIndirects);
      // console.log("newBehaviours:", newBehaviours);

      setDirectControls(newDirects);
      setIndirectControls(newIndirects);
      setBehaviourControls(newBehaviours);

      setValue("directControls", newDirects);
      setValue("indirectControls", newIndirects);
      setValue("behaviour", newBehaviours);
    } else {
      console.log("Category not found");
    }
  };

  //Confirm Add Button
  const handleButton = () => {
    //These are the latest contents of these form variables of the CheckBox
    const selectedDirect = getValues("directControls");
    const selectedIndirect = getValues("indirectControls");
    const selectedBehaviour = getValues("behaviour");

    //at least one control must be ticked!
    if (
      selectedDirect.length === 0 &&
      selectedIndirect.length === 0 &&
      selectedBehaviour.length === 0
    ) {
      setErrMsg("No controls selected. Please select one.");
      return false;
    }
    setErrMsg("");
    // console.log("categoryList: ", categoryList);
    const selectedIndex = arrSelected.indexOf(index); //'index' is state variable that is updated at each button click of the Golden Rules
    // console.log("index: ", index);
    // console.log("selected: ", selectedIndex);
    // console.log("before: ", categoryList);

    //disable the button
    setIsConfirmed(true);

    //if not in the array arrSelected then this is a new category, add it in
    if (selectedIndex === -1) {
      setCategoryList([...categoryList, categories[index]]); //only record the category into the categoryList if the Confirm button is pressed`
      if (arrSelected.length < 11) {
        setArrSelected([...arrSelected, index]); //add the latest clicked button if the array is not full yet (10 golden rules)
      }
      // console.log("directControls: ", getValues("directControls"));
      // console.log("indirectControls: ", getValues("indirectControls"));
      // console.log("behaviour", getValues("behaviour"));

      //These are the latest contents of these form variables of the CheckBox
      const selectedDirect = getValues("directControls");
      const selectedIndirect = getValues("indirectControls");
      const selectedBehaviour = getValues("behaviour");

      //update the controls
      setDirectControls([...directControls, selectedDirect]);
      setIndirectControls([...indirectControls, selectedIndirect]);
      setBehaviourControls([...behaviourControls, selectedBehaviour]);

      // There's no need for this. This is not used anywhere.
      // setAllControls([
      //   ...directControls,
      //   ...indirectControls,
      //   ...behaviourControls,
      // ]);

      //update the values of the form variables of the CheckBox
      setValue("directControls", [...directControls, selectedDirect]);
      setValue("indirectControls", [...indirectControls, selectedIndirect]);
      setValue("behaviour", [...behaviourControls, selectedBehaviour]);

      //these should show the updated values
      // console.log("directControls: ", getValues("directControls"));
      // console.log("indirectControls: ", getValues("indirectControls"));
      // console.log("behaviour", getValues("behaviour"));

      const allControls = [
        ...directControls,
        selectedDirect,
        ...indirectControls,
        selectedIndirect,
        ...behaviourControls,
        selectedBehaviour,
      ];

      const latestDirects = [...directControls, selectedDirect];
      const latestIndirects = [...indirectControls, selectedIndirect];
      const latestBehaviours = [...behaviourControls, selectedBehaviour];

      // console.log(latestDirects);
      // console.log(latestIndirects);
      // console.log(latestBehaviours);

      // const critical = selectedDirect.map((c) => {
      //   return c.description;
      // });
      // const standard = selectedIndirect.map((i) => {
      //   return i.description;
      // });
      // const behaviour = selectedBehaviour.map((b) => {
      //   return b.description;
      // });
      const critical = latestDirects.map((c) => {
        return c.map((c1) => {
          return "• " + c1.description + "\n";
        });
      });
      // console.log("critical: ", critical);
      // console.log("critical string: ", critical[0].join(""));
      const standard = latestIndirects.map((i) => {
        return i.map((i1) => {
          return "• " + i1.description + "\n";
        });
      });

      const behaviour = latestBehaviours.map((b) => {
        return b.map((b1) => {
          return "• " + b1.description + "\n";
        });
      });

      // console.log("critical: ", critical);
      // console.log("standard: ", standard);
      // console.log("behaviour: ", behaviour);

      const fcritical = critical.map((c) => {
        return c.join("");
      });

      const fstandard = standard.map((c) => {
        return c.join("");
      });
      const fbehaviour = behaviour.map((c) => {
        return c.join("");
      });

      setValue(
        "controls",
        "CRITICAL CONTROLS:\n" +
          fcritical.join("") +
          "\nSTANDARD CONTROLS:\n" +
          fstandard.join("") +
          "\nBEHAVIOUR CONTROLS:\n" +
          fbehaviour.join("")
      );
    }
  };

  //ShowMore button
  const handleShow = () => {
    setShowDetails(false);

    setShowMore(false);
    const grButtons = [
      {
        clickParam: "01-HEIGHTS & DROPPED OBJECTS",
        imgSrc: "SGR-icon-fallinObject.png",
        disabled: false,
        variant: "outlined",
      },

      {
        clickParam: "02-PEOPLE & PLANT",
        imgSrc: "SGR-icon-peopleplant.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "03-ROADS & RAIL",
        imgSrc: "SGR-icon-railroad.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "04-LIFTING OPERATIONS",
        imgSrc: "SGR-icon-lifting.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "05-TEMPORARY WORKS",
        imgSrc: "SGR-icon-temp.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "06-LIQUID BODIES",
        imgSrc: "SGR-icon-water.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "07-ENERGY ISOLATION",
        imgSrc: "SGR-icon-energiso.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "08-ELECTRICAL EQUIPMENT",
        imgSrc: "SGR-icon-elec.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "09-EXCAVATION",
        imgSrc: "SGR-icon-excav.png",
        disabled: false,
        variant: "outlined",
      },
      {
        clickParam: "10-CONFINED SPACE",
        imgSrc: "SGR-icon-confined.png",
        disabled: false,
        variant: "outlined",
      },
    ];

    //array containing the selected GR button - this is where marking of selected GR is done
    //contains all the indices of all the GR buttons that was pressed/selected. The index is only added in this array if the 'Confirm Add' button was pressed.
    arrSelected.forEach((d) => {
      grButtons[d].variant = "contained"; //to mark as selected
      grButtons[d].disabled = submitted;
    });

    setGoldenRule([...grButtons]); //update the state variable
    setShowMore(false);
  };

  return (
    <Container>
      {/* The Golden Rules buttons */}
      <Grid container spacing={2}>
        <GoldenRuleButton
          handleClick={handleClick}
          goldenRule={goldenRule}
          showMore={showMore}
          submitted={submitted}
        />
      </Grid>
      {showMore && (
        <Button
          onClick={handleShow}
          sx={{
            padding: 1,
            marginTop: 1,
            marginBottom: 1,
          }}
          variant="contained"
          color="primary"
          fullWidth
        >
          <Typography>Show more ...</Typography>
        </Button>
      )}

      <Stack>
        {showDetails && (
          <>
            <FormInputMultiCheckboxCustom
              name="directControls"
              control={control}
              setValue={setValue}
              label="Critical Controls"
              options={directControlList}
              {...register("directControls", { required: true })}
              isDisabled={submitted}
              details={details}
            />
            {errors.directControls && (
              <Typography variant="caption" sx={{ margin: 1, color: "red" }}>
                Select one answer
              </Typography>
            )}
          </>
        )}
      </Stack>

      <Stack>
        {showDetails && (
          <>
            <FormInputMultiCheckboxCustom
              name="indirectControls"
              control={control}
              setValue={setValue}
              label="Standard Controls"
              options={indirectControlList}
              {...register("indirectControls", { required: true })}
              isDisabled={submitted}
              details={details}
            />
            {errors.indirectControls && (
              <Typography variant="caption" sx={{ margin: 1, color: "red" }}>
                Select one answer
              </Typography>
            )}
          </>
        )}
      </Stack>

      <Stack>
        {showDetails && (
          <>
            <FormInputMultiCheckboxCustom
              name="behaviour"
              control={control}
              setValue={setValue}
              label="Behaviour"
              options={behaviourControlList}
              {...register("behaviour", { required: true })}
              isDisabled={submitted}
              details={details}
            />
            {errors.behaviour && (
              <Typography variant="caption" sx={{ margin: 1, color: "red" }}>
                Select one answer
              </Typography>
            )}
          </>
        )}
      </Stack>
      {showDetails && (
        <>
          <Stack>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "25px",
                padding: 1,
                marginTop: 2,
                marginBottom: 2,
              }}
              onClick={handleButton}
              disabled={submitted || isConfirmed}
            >
              Confirm Add
            </Button>
            {errMsg && (
              <Typography sx={{ color: "red", textAlign: "center" }}>
                {errMsg}
              </Typography>
            )}
          </Stack>
        </>
      )}
      <Stack sx={{ margin: 2 }}>
        <Typography>Critical Controls</Typography>
        <TextareaAutosize
          aria-label="empty textarea"
          placeholder="List controls"
          minRows={10}
          name="controls"
          label="CONTROLS"
          {...register("controls")}
          disabled={submitted}
        />
      </Stack>
    </Container>
  );
};

export default GoldenRules;
