import { Navigate, Outlet } from "react-router-dom";
// import { useContext } from "react";
// import { LoginContext } from "../context/LoginContext";
import { useLoginContext } from "../hooks/useLoginContext";

const ProtectedRoutes = () => {
  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }
  const { token } = useLoginContext();
  // const { token } = useContext(LoginContext);
  // console.log("token: ", token);

  const isAuthenticated = !isEmpty(token) && token.token.length > 0;
  // console.log("auth: ", isAuthenticated);
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
  // return isAuthenticated ? <Outlet /> : <Navigate to="/logout" />;
};

export default ProtectedRoutes;
