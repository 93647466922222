import { useState, useEffect } from "react";
import { useParams } from "react-router";

import QRCode from "react-qr-code";

import axios from "axios";

import { useForm } from "react-hook-form";

import { useAttendeesContext } from "../hooks/useAttendeesContext";
import { useEmployersContext } from "../hooks/useEmployersContext";

import { FormInputMultiCheckbox } from "./form-components/FormInputMultiCheckbox";
import { FormInputDropdown } from "./form-components/FormInputDropdown";
import { FormInputDropdownCustom } from "./form-components/FormInputDropdownCustom";
// import { FormInputDropdownProject } from "./form-components/FormInputDropdownProject";
import { FormInputRadio } from "./form-components/FormInputRadio";

import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

import { Container } from "@mui/material";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "./Loading";

import GoldenRules from "./GoldenRules";

import ListAttend from "./ListAttend";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

//  window.location.protocol + "//" + window.location.host + "/getsc/" + row._id;

//Create QR Code component
const CreateQRCode = ({ url, setIsLoading }) => {
  // console.log("host: ", window.location.host);
  // console.log("protocol: ", window.location.protocol);
  setIsLoading(false);
  return (
    <>
      <Dialog open={true} fullWidth>
        <Container>
          <Typography sx={{ textAlign: "center", padding: 1 }}>
            {url && (
              <a
                href={window.location.protocol + "//" + url}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {window.location.protocol + "//" + url}
              </a>
            )}
          </Typography>

          <div
            style={{
              height: "auto",
              margin: "20px auto",
              maxWidth: 200,
              width: "100%",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={window.location.protocol + "//" + url}
              viewBox={`0 0 256 256`}
            />
          </div>
        </Container>
      </Dialog>
    </>
  );
};

//socket here is passed from the App.js
export default function StartCard({ socket }) {
  useForm({
    defaultValues: {
      dateStartCard: new Date(),
      employer: "",
      workLocation: "",
      task: "",
      hazards: "",
    },
  });
  const { projectId } = useParams(); //the way to get the params of the URL -> now using this as of: 29-Oct-2024
  // console.log("projectId: ", projectId);
  const { dispatch } = useEmployersContext();

  const { employees } = useAttendeesContext(); //now using 'context' to solve issues on updating actions on the employee list: 10-Sept-2024
  const { employers } = useEmployersContext(); //now using 'context' to solve issues on updating actions on the employee list: 10-Sept-2024

  const { handleSubmit, control, setValue, formState, register, getValues } =
    useForm();
  const { errors } = formState;
  // const { setValue } = useFormContext("");

  const [url, setUrl] = useState(""); //URL created after submission

  const [projects, setProjects] = useState([]);
  // const [projectId, setProjectId] = useState("");
  const [projectName, setProjectName] = useState("");
  // projectName;

  const [version, setVersion] = useState(null);
  // const [employers, setEmployers] = useState([]); //when initial value is 'null', this causes error due to the map command which is expecting an array.
  const [isLoading, setIsLoading] = useState(false);
  // const [employees, setEmployees] = useState([]);
  const [submitted, setIsSubmitted] = useState(false);
  // const [isAdd, setIsAdd] = useState(false);
  const [isLoadingAttendees, setIsLoadingAttendees] = useState(false);
  const [isLoadingEmployers, setIsLoadingEmployers] = useState(false);

  const [file, setFile] = useState(null);
  const [filepreview, setFilePreview] = useState("");

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [hazardString, setHazardString] = useState("");
  const [controls, setControls] = useState([]);

  const [open, setOpen] = useState(true);
  const [openMsg, setOpenMsg] = useState(false);
  const [msg, setMsg] = useState(null);
  const [severity, setSeverity] = useState("success");

  const [locationList, setLocationList] = useState([]);

  const [categoryId, setCategoryId] = useState("");
  const [categoryList, setCategoryList] = useState([]);

  const [errMsg, setErrMsg] = useState(null);
  const [completed, setCompleted] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [fileMsg, setFileMsg] = useState(null);

  const highRiskWorks = [
    {
      label: "Working at Height > 2m",
      value: "1",
    },
    {
      label: "Demolition of load bearing structure",
      value: "2",
    },
    {
      label: "Likely to involve asbestos",
      value: "3",
    },
    {
      label: "Temporary Load Bearing support",
      value: "4",
    },
    {
      label: "On/Near Electrical installations",
      value: "5",
    },
    // {
    //   label: "Area with a contaminated atmosphere",
    //   value: "6",
    // },
    {
      label: "Tilt-up/Pre-cast concrete elements",
      value: "7",
    },
    // {
    //   label: "Adjacent to Road or Railway line",
    //   value: "8",
    // },
    {
      label: "Work in/near a Confined Space",
      value: "9",
    },
    {
      label: "Work in/near a Trench > 1.5m",
      value: "10",
    },
    {
      label: "Work on/near pressurised Gas",
      value: "11",
    },
    {
      label: "Work on/near Chemical or Fuel Lines",
      value: "12",
    },
    {
      label: "Area with Powered Mobile Plant",
      value: "13",
    },
    // {
    //   label: "Areas with artificial Extremes of temperatures",
    //   value: "14",
    // },
    // {
    //   label: "Near water or liquid where there is a risk of Drowning",
    //   value: "15",
    // },
    {
      label: "No High Risk Construction Work applies",
      value: "0",
    },
  ];

  const yesno = [
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ];
  const na = [
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
    {
      label: "N/A",
      value: "na",
    },
  ];

  const onChange = (e) => {
    const fileData = e.target.files[0];
    // console.log(e.target.files[0]);
    console.log(fileData);
    if (fileData.size > 10000000) {
      setFileMsg("File too large - greater than 10MB");
      return false;
    } else {
      setFileMsg(null);
      setFile(e.target.files[0]); //this solved the issue with uploading from an iPhone. Well done Gilberto! --> 16-May-24
      // setFilename(e.target.files[0].name);

      setFilePreview(URL.createObjectURL(e.target.files[0]));

      // Image preview
      const reader = new FileReader();
      reader.onload = function (e) {
        reader.readAsDataURL(e.target.files[0]);

        return true;
      };
    }
  };

  //runs at the start of this component
  useEffect(() => {
    // setIsLoading(true);
    const clearCacheData = () => {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // alert("Complete Cache Cleared");
    };

    const getProjects = async () => {
      const projects = await axios.get("/api/sc/getprojects");
      // console.log("Projects: ", projects.data);
      // localStorage.setItem("projects", JSON.stringify(projects.data));

      try {
        const projectsData = projects.data[0].map((project) => {
          return {
            value: { projectId: project.projectId, projectName: project.name },
            label: project.name,
          };
        });
        //sort projects by name
        projectsData.sort((a, b) => {
          let fa = a.label.toLowerCase(),
            fb = b.label.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const projectName = projectsData.filter((p) => {
          return p.value.projectId === projectId;
        });
        // console.log(projectName[0].label);
        setProjectName(projectName[0].label);

        setProjects(projectsData);
        setVersion(projects.data[1]);
        return projectsData;
      } catch (err) {
        console.log(err);
      }
    };
    const getEmployers = async () => {
      setIsLoadingEmployers(true);
      const employers = await axios.get(`/api/sc/getemployers/${projectId}`);
      // localStorage.setItem("employers", JSON.stringify(employers.data));

      const employersData = employers.data.map((employer) => {
        return {
          value: employer.id,
          label: employer.name,
        };
      });

      //sort Employers by name
      employersData.sort((a, b) => {
        let fa = a.label.toLowerCase(),
          fb = b.label.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      // console.log(employersData);
      // setEmployers(employersData);

      dispatch({ type: "SET_EMPLOYERS", payload: employersData }); //using 'context' to manage these data on employers: 19-Sept-2024
      setIsLoadingEmployers(false);
    };

    const getVersion = async () => {
      const version = await axios.get("/api/sc/getversion/");
      console.log("version: ", version.data);
      setVersion(version.data);
    };

    const getLocations = async () => {
      await axios.get(`api/sc/locations/${projectId}`).then((response) => {
        //  setLocations(response.data);
        const locationOptions = response.data.map((l) => {
          return { label: l.location, value: l.location };
        });
        setLocationList(locationOptions);
      });
    };
    clearCacheData();
    getProjects();
    getVersion();
    getEmployers();
    getLocations();

    // setIsLoading(false);

    socket.emit("newcard");
  }, [socket, projectId, dispatch, submitted]);

  // submittedcard

  // useEffect(() => {
  //   socket.emit("newcard");
  // }, []);

  //submit START card
  const onSubmit = async (d) => {
    console.log("data: ", d);
    if (
      categoryList?.length === 0

      // projectName.length === 0 ||
      // d.employer.length === 0 ||
      // // employerName.length === 0 ||
      // d.hrcw.length === 0 ||
      // controlList.length === 0 ||
      // categoryList.length === 0 ||
      // d.step1.length === 0 ||
      // d.step2.length === 0 ||
      // d.step3.length === 0 ||
      // d.step4.length === 0 ||
      // d.step5.length === 0 ||
      // d.step6.length === 0 ||
      // d.workers.length === 0 ||
      // (d.directControls.length === 0 &&
      //   d.indirectControls.length === 0 &&
      //   d.behaviour === 0)
    ) {
      setErrMsg("Submission not allowed. No Golden Rule selected.");

      setIsSubmitted(false);

      return false;
    }

    // console.log("employees: ", employees);

    //check for any employees that signed
    const signedEmployees = employees.filter((e) => {
      return e.isSigned;
    });
    // console.log("signedEmployees: ", signedEmployees);
    if (
      (d.directControls === undefined &&
        d.indirectControls === undefined &&
        d.behaviour === undefined) ||
      employees === undefined ||
      signedEmployees.length === 0
    ) {
      setErrMsg(
        "No Golden Rule controls or workers selected. Please fill-in these data."
      );
      setIsLoading(false);
      return false;
    }
    setIsLoading(true);
    setErrMsg("");
    const controlList = [
      ...d.directControls,
      ...d.indirectControls,
      ...d.behaviour,
    ];

    // console.log("controlList: ", controlList);

    const employerName = employers.filter((e) => {
      return e.value === d.employer;
    });

    //get the project name
    // const projectName = projects.filter((p) => {
    //   return p.value.projectId === d.project;
    // });
    // console.log("Project name: ", projectName);

    //get employer name

    // console.log("Employer name: ", projectName);

    // console.log("form values ", d);
    // console.log("signature: ", employees);
    setIsSubmitted(true);

    const formData = new FormData();
    formData.append("workDate", new Date());
    formData.append("projectId", projectId); //from params
    // formData.append("projectName", projectName[0].label);
    formData.append("projectName", projectName); //state variable
    formData.append("employerId", d.employer);
    formData.append("employerName", employerName[0].label);
    formData.append("task", d.task);
    formData.append("location", d.workLocation);
    // formData.append("hazards", d.hazardOptions);
    // formData.append("controls", JSON.stringify(controlList)); //combined critical (direct) + standard (indirect) + behaviour controls
    formData.append("controls", d.controls); //combined critical (direct) + standard (indirect) + behaviour controls
    formData.append("categories", JSON.stringify(categoryList)); //state variable
    formData.append("critical", JSON.stringify(d.directControls)); //from FormInputMultiCheckBoxCustom
    formData.append("standard", JSON.stringify(d.indirectControls)); //from FormInputMultiCheckBoxCustom
    formData.append("behaviour", JSON.stringify(d.behaviour)); //from FormInputMultiCheckBoxCustom
    formData.append("hrcw", d.hrcw);
    formData.append("step1", d.step1);
    formData.append("step2", d.step2);
    formData.append("step3", d.step3);
    formData.append("step4", d.step4);
    formData.append("step5", d.step5);
    formData.append("step6", d.step6);
    formData.append("conditions", "none");
    formData.append("management", "none");
    formData.append("workers", JSON.stringify(employees)); //from a separate array
    formData.append("image", file);
    formData.append("categoryId", categoryId); //state variable

    // console.log("formData: ", formData);

    try {
      const response = await axios.post(`/api/sc/startcard`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      });
      // console.log("response.data: ", response.data);
      const urlString =
        window.location.host + "/getsc/" + response.data.data._id;
      // console.log(urlString);
      setUrl(urlString);
      // setOpenMsg(true); //show the Snackbar message
      // setMsg("Close out record updated!");
      // setSeverity("success");
      setOpen(false);
      socket.emit("cardsubmitted", d.employer, d.task);
    } catch (error) {
      console.log(error);
      setOpenMsg(true); //show the Snackbar message
      setMsg(error.response.data);
      setErrMsg(error.response.data + " limit is 10MB");
      setSeverity("error");
    }
  };

  return (
    <Container>
      {/* {!version && <CircularProgress />} */}
      <Stack
        sx={{ marginTop: 2, backgroundColor: "green", borderRadius: "25px" }}
      >
        {version && (
          <>
            <Typography
              sx={{
                color: "white",
                padding: 1,
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              S.T.A.R.T Card v{version}
            </Typography>
          </>
        )}
      </Stack>

      <Typography
        sx={{ textAlign: "center", marginTop: 1, fontWeight: "bold" }}
      >
        {projectName}
      </Typography>

      <Stack>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {/* <DialogContent sx={{ margin: 1, padding: 1 }}>
            <FormInputDropdownProject
              name="project"
              control={control}
              label="Project"
              options={projects}
              isDisabled={submitted}
              getValues={getValues}
              setIsLoadingEmployers={setIsLoadingEmployers}
              setProjectId={setProjectId}
              setLocationList={setLocationList}
            />
          </DialogContent> */}
          {isLoadingEmployers && <CircularProgress />}
          {employers && (
            <Stack>
              <DialogContent sx={{ margin: 1, padding: 1 }}>
                <FormInputDropdownCustom
                  name="employer"
                  control={control}
                  label="Employer"
                  options={employers}
                  isDisabled={submitted}
                  getValues={getValues}
                  setIsLoadingAttendees={setIsLoadingAttendees}
                  projectId={projectId}
                  setCompleted={setCompleted}
                  completed={completed}
                  {...register("employer", { required: true })}
                />
                {errors.employer && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    Select employer
                  </Typography>
                )}
              </DialogContent>
              {locationList && (
                <DialogContent sx={{ margin: 1, padding: 1 }}>
                  <FormInputDropdown
                    name="workLocation"
                    control={control}
                    label="Work Location"
                    options={locationList}
                    isDisabled={submitted}
                    setCompleted={setCompleted}
                    completed={completed}
                    getValues={getValues}
                    {...register("workLocation", { required: true })}
                  />
                  {errors.workLocation && (
                    <Typography variant="caption" sx={{ color: "red" }}>
                      Select work location
                    </Typography>
                  )}
                </DialogContent>
              )}

              <DialogContent sx={{ margin: 1, padding: 1 }}>
                <TextField
                  name="task"
                  label="Task"
                  {...register("task", { required: true })}
                  error={!!errors.remarks}
                  helperText={errors.remarks?.message}
                  required
                  disabled={submitted}
                  fullWidth
                />
                {errors.task && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    Fill-in task
                  </Typography>
                )}
              </DialogContent>
              <Stack sx={{ margin: 2, backgroundColor: "purple" }}>
                <Typography
                  sx={{
                    color: "white",
                    padding: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  STEP 1 - STOP : Identify High Risk Construction Work
                </Typography>
              </Stack>
              <DialogContent
                sx={{
                  margin: 1,
                  padding: 1,
                }}
              >
                <FormInputMultiCheckbox
                  name="hrcw"
                  control={control}
                  setValue={setValue}
                  label="High Risk Construction Works *"
                  options={highRiskWorks}
                  {...register("hrcw", { required: true })}
                  isDisabled={submitted}
                  setCompleted={setCompleted}
                  completed={completed}
                />
                {errors.hrcw && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    Make a selection
                  </Typography>
                )}
              </DialogContent>
              <Stack sx={{ margin: 2, backgroundColor: "red" }}>
                <Typography
                  sx={{
                    color: "white",
                    padding: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  STEP 2 - THINK : Complete this START Card at your work
                  location / area
                </Typography>
              </Stack>

              <FormInputRadio
                name="step1"
                control={control}
                label="All required permits to work (PTW) have been approved and signed off"
                options={na}
                isDisabled={submitted}
                {...register("step1", { required: true })}
              />

              {errors.step1 && (
                <Typography
                  variant="caption"
                  sx={{ marginLeft: 6, color: "red" }}
                >
                  Select one answer
                </Typography>
              )}
              <FormInputRadio
                name="step2"
                control={control}
                label="Plant operators hold the relevant licence and Plant Operators Assessment"
                options={na}
                isDisabled={submitted}
                {...register("step2", { required: true })}
              />
              {errors.step2 && (
                <Typography
                  variant="caption"
                  sx={{ marginLeft: 6, color: "red" }}
                >
                  Select one answer
                </Typography>
              )}
              <FormInputRadio
                name="step3"
                control={control}
                label="Correct tools / equipment and PPE are available"
                options={yesno}
                isDisabled={submitted}
                {...register("step3", { required: true })}
              />
              {errors.step3 && (
                <Typography
                  variant="caption"
                  sx={{ marginLeft: 6, color: "red" }}
                >
                  Select one answer
                </Typography>
              )}
              <FormInputRadio
                name="step4"
                control={control}
                label="SWMS have been read and understood for all High Risk Construction Work"
                options={yesno}
                isDisabled={submitted}
                {...register("step4", { required: true })}
              />
              {errors.step4 && (
                <Typography
                  variant="caption"
                  sx={{ marginLeft: 6, color: "red" }}
                >
                  Select one answer
                </Typography>
              )}
              <FormInputRadio
                name="step5"
                control={control}
                label="Exclusion zones required to protect others have been established"
                options={na}
                isDisabled={submitted}
                {...register("step5", { required: true })}
              />
              {errors.step5 && (
                <Typography
                  variant="caption"
                  sx={{ marginLeft: 6, color: "red" }}
                >
                  Select one answer
                </Typography>
              )}
              <FormInputRadio
                name="step6"
                control={control}
                label="if required, a rescue / retrieval plan has been developed and approved"
                options={na}
                isDisabled={submitted}
                {...register("step6", { required: true })}
              />
              {errors.step6 && (
                <Typography
                  variant="caption"
                  sx={{ marginLeft: 6, color: "red" }}
                >
                  Select one answer
                </Typography>
              )}
              <Stack sx={{ margin: 2, backgroundColor: "orange" }}>
                <Typography
                  sx={{
                    color: "white",
                    padding: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  STEP 3 - ASSESS : Discuss the hazards and controls with the
                  group
                </Typography>
              </Stack>
              {projectId && (
                <Stack>
                  <Typography
                    variant="h6"
                    sx={{ margin: 2, fontWeight: "bold" }}
                  >
                    GOLDEN RULES
                  </Typography>
                  <GoldenRules
                    hazardString={hazardString}
                    setHazardString={setHazardString}
                    controls={controls}
                    setControls={setControls}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    register={register}
                    submitted={submitted}
                    setCategoryId={setCategoryId}
                    projectId={projectId}
                    categoryList={categoryList}
                    setCategoryList={setCategoryList}
                    errors={errors}
                    isConfirmed={isConfirmed}
                    setIsConfirmed={setIsConfirmed}
                  />
                </Stack>
              )}

              <Stack sx={{ margin: 2, backgroundColor: "lightgreen" }}>
                <Typography
                  sx={{
                    color: "white",
                    padding: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  STEP 4 - REVIEW : Monitor conditions and check for change
                </Typography>
              </Stack>
              <Stack sx={{ margin: 2 }}>
                <Typography>None</Typography>
              </Stack>
              {/* <Stack sx={{ margin: 2 }}>
                <Typography>CHANGE IN CONDITIONS</Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="List change in conditions"
                  minRows={10}
                  name="conditions"
                  label="Change in conditions"
                  {...register("conditions")}
                  disabled={submitted}
                />
              </Stack>
              <Stack sx={{ margin: 2 }}>
                <Typography>CHANGE IN MANAGEMENT</Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="List change in management"
                  minRows={10}
                  name="management"
                  label="Change in management"
                  {...register("management")}
                  disabled={submitted}
                />
              </Stack> */}

              <Stack sx={{ margin: 2, backgroundColor: "green" }}>
                <Typography
                  sx={{
                    color: "white",
                    padding: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  STEP 5 - TALK : Review, agree and sign on
                </Typography>
              </Stack>
              <DialogContent sx={{ margin: 1, padding: 1 }}>
                <Typography>Upload Photo *</Typography>

                <TextField
                  {...register("file", { required: true })}
                  name="file"
                  type="file"
                  control={control}
                  onChange={onChange}
                  required
                  disabled={submitted}
                />

                {errors.file && (
                  <Typography
                    variant="caption"
                    sx={{ color: "red", marginLeft: 2 }}
                  >
                    Please upload a photo
                  </Typography>
                )}
                {fileMsg && (
                  <Typography sx={{ color: "red" }}>{fileMsg}</Typography>
                )}

                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                  {file ? (
                    <img
                      src={filepreview}
                      style={{
                        width: "100px",
                        height: "100px",
                        marginTop: "10px",
                        boxShadow: "5px 5px 5px lightgray",
                      }}
                      alt="preview"
                    />
                  ) : null}
                </Stack>
              </DialogContent>
              {isLoadingAttendees && <CircularProgress />}
              {employees && <ListAttend submitted={submitted} />}
              {isConfirmed && fileMsg === null && (
                <DialogContent sx={{ margin: 1, padding: 1 }}>
                  <Stack>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        borderRadius: "25px",
                        padding: 1,
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                      // disabled={submitted || completed.length < 14}
                      disabled={submitted}
                    >
                      Submit START Card
                    </Button>
                    {errMsg && (
                      <Typography sx={{ color: "red", textAlign: "center" }}>
                        {errMsg}
                      </Typography>
                    )}
                  </Stack>
                </DialogContent>
              )}
            </Stack>
          )}
          {/* <Progress percentage={uploadPercentage} /> */}
          {isLoading && msg === null && (
            <Loading isLoading={isLoading} message={"Submitting form..."} />
          )}
        </form>
      </Stack>

      {url && <CreateQRCode url={url} setIsLoading={setIsLoading} />}
    </Container>
  );
}
