import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";

import axios from "axios";

import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

import { useForm } from "react-hook-form";

function AddSignaturePad({ setDoSign, setStartCard, startcard }) {
  //react-hook-form values
  useForm({
    defaultValues: {
      name: "",
      imgUri: "",
    },
  });
  const { handleSubmit, formState, register } = useForm();
  const { errors } = formState;

  //state variables here
  const [sign, setSign] = useState();
  const [url, setUrl] = useState();
  const [open, setOpen] = useState(true);
  const [errMsg, setErrMsg] = useState(null);

  const handleClear = () => {
    sign.clear();
    setUrl("");
  };

  // const handleGenerate = () => {
  //   setUrl(sign.getTrimmedCanvas().toDataURL("image/png"));
  //   setOpen(false);
  //   setDoSign(false);
  // };

  const onSubmit = async (d) => {
    // console.log("sign: ", sign);
    // console.log("isEmpty: ", sign.isEmpty());
    if (sign.isEmpty()) {
      setErrMsg("Please sign.");
      return false;
    }
    d.imgUrl = sign.getTrimmedCanvas().toDataURL("image/png");
    // console.log("data: ", d);
    // console.log("startcard: ", startcard);
    const newWorkers = [...startcard.workers, d];
    startcard.workers = newWorkers;
    // setStartCard(...startcard);

    setUrl(sign.getTrimmedCanvas().toDataURL("image/png"));
    setOpen(false);
    setDoSign(false);
    const response = await axios.patch("/api/sc/scsignupdate", startcard); //call the backend
    // console.log(response.data);
  };

  //Note: onSubmit method of the <form> will work only with the <Dialog> component if it is inside it. --> 20-Oct-2024
  return (
    <Stack>
      <Dialog open={open} onClose={handleClear} fullWidth>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
            {"Signature"}
          </DialogTitle>
          <DialogContent sx={{ margin: 1, padding: 1 }}>
            <TextField
              name="name"
              label="Name"
              {...register("name", { required: true })}
              error={!!errors.name}
              helperText={errors.name?.message}
              required
              fullWidth
            />
            {errors.name && (
              <Typography variant="caption" sx={{ color: "red" }}>
                Fill-in name
              </Typography>
            )}
          </DialogContent>

          <Stack>
            <Typography sx={{ margin: 1, padding: 1 }}>Sign here: </Typography>
            <SignatureCanvas
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
              minDistance={0}
              ref={(data) => setSign(data)}
            />
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: "end", margin: 2 }}
          >
            <Button variant="outlined" color="primary" onClick={handleClear}>
              Clear
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="error"
              // onClick={handleGenerate}
              autoFocus
            >
              Save
            </Button>
          </Stack>
        </form>
        {errMsg && (
          <Typography sx={{ textAlign: "center", color: "red" }}>
            {errMsg}
          </Typography>
        )}
      </Dialog>
    </Stack>
  );
}
export default AddSignaturePad;
