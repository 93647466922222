import { useState, useEffect, useContext } from "react";
import axios from "axios";

// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Grid from "@mui/material/Unstable_Grid2";

import ResponsiveAppBar from "./ResponsiveAppBar";
// import Hazard from "./Hazard";
import Direct from "./Direct";
import Indirect from "./Indirect";
import Behaviour from "./Behaviour";

import { LoginContext } from "../context/LoginContext";

export default function HazardControl() {
  const { token } = useContext(LoginContext);

  const [categories, setCategories] = useState([]);
  const [display, setDisplay] = useState([]);
  const [categoryObj, setCategoryObj] = useState({});

  useEffect(() => {
    const getCategories = async () => {
      try {
        await axios.get(`/api/sc/category/${token.projectId}`).then((data) => {
          // console.log(data.data);
          setCategories(data.data);
          const forDisplay = data.data.map((d) => {
            return d.category;
          });
          // console.log(forDisplay);
          setDisplay(forDisplay);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getCategories(); //This is the right way to call a useEffect function --> React gives a warning if this is not the case
  }, []);

  useEffect(() => {
    // console.log("Hey!", categoryObj);
  }, [categoryObj]);

  const handleChange = (event) => {
    // console.log(event.target.dataset.optionIndex); //DOMStringMap
    const idx = parseInt(event.target.dataset.optionIndex);
    const obj = {
      id: categories[idx]._id,
      category: categories[idx].category,
    };
    // console.log(obj);
    setCategoryObj(obj);
  };

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Autocomplete
          disablePortal
          options={display}
          renderInput={(params) => <TextField {...params} label="Categories" />}
          onChange={handleChange}
          autoHighlight
          sx={{ mt: 2 }}
        />
      </Container>

      {categoryObj.id && (
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Direct category={categoryObj} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Indirect category={categoryObj} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Behaviour category={categoryObj} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
