import { useState, useEffect, useContext } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
// import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
// import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Chip from "@mui/material/Chip";

import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
// import Container from "@mui/material/Container";

// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";

// import FormControl from "@mui/material/FormControl";
// import ResponsiveAppBar from "./ResponsiveAppBar";

import { LoginContext } from "../context/LoginContext";

export default function Behaviour(category) {
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const { token } = useContext(LoginContext);

  const form = useForm({
    defaultValues: {
      behaviour: "",
    },
  });
  const { register, handleSubmit, formState, watch, getValues, setValue } =
    form;
  const { errors } = formState;

  const [open, setOpen] = useState(true);
  // const [categories, setCategories] = useState([]);
  const [behaviours, setBehaviours] = useState([]);
  const [isAdding, setIsAdding] = useState(null); //defaults to Adding
  const [index, setIndex] = useState(null);

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const navigate = useNavigate();

  // console.log("category ", category);
  // console.log("category id", category.category.id);

  useEffect(() => {
    // console.log("category ", category);
    // console.log("category id", category.id);
    const getBehaviours = async () => {
      try {
        await axios
          .get(`/api/sc/behaviour/${category.category.id}`)
          .then((data) => {
            // console.log(data.data);
            setBehaviours(data.data);
          });
      } catch (error) {
        console.log(error);
      }
    };
    getBehaviours();
  }, [category]);

  //this useEffect's purpose is just to refresh the screen when the delete or save button is click
  useEffect(() => {
    // console.log(behaviours);
  }, [behaviours, category]);

  const onSubmit = async (data) => {
    // data.categoryId = category.id;
    // data.category = category;
    let newData = {
      ...data,
      categoryId: category.category.id,
      category: category.category.category,
      projectId: token.projectId,
    };
    // console.log("new data: ", newData);
    // console.log(data);
    if (isAdding) {
      try {
        await axios.post("/api/sc/behaviour", newData).then((data) => {
          // console.log("server response: ", data);
          setBehaviours([...behaviours, data.data.data]);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      //Editing here
      if (index >= 0) {
        const id = behaviours[index]._id;
        try {
          await axios.post(`/api/sc/behaviour/${id}`, data).then((data) => {
            // console.log("server response: ", data);

            const nextbehaviours = behaviours.map((d, i) => {
              if (i === index) {
                // Replace the edited element
                d.behaviour = getValues("behaviour");
                return d;
              } else {
                // The rest haven't changed
                return d;
              }
            });
            setBehaviours(nextbehaviours);
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    setIsAdding(null);
  };

  const handleClose = () => {
    navigate("/dashboard", { replace: true });
    setOpen(false);
  };

  const handleDelete = async (idx) => {
    setBehaviours(
      behaviours.filter((d) => {
        return d._id !== behaviours[idx]._id;
      })
    );
    if (behaviours[idx]._id) {
      try {
        await axios
          .delete(`api/sc/behaviour/${behaviours[idx]._id}`)
          .then((d) => {
            // console.log(d);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(behaviours[idx], idx);
    }
  };
  const handleEdit = async (idx) => {
    setIsAdding(false);
    setIndex(idx);
    // console.log("Editing: ", behaviours[idx].behaviourControl);
    setValue("behaviour", behaviours[idx].behaviour);
  };

  const handleAdd = () => {
    setIsAdding(true);
  };

  return (
    <div style={{ marginLeft: "50px" }}>
      <DialogTitle>
        <Typography
          sx={{ fontWeight: "bold", textAlign: "center" }}
          variant="h4"
        >
          behaviour updates
        </Typography>
        {category.category.id && (
          <Typography sx={{ textAlign: "center" }} variant="h6">
            {category.category.category}
          </Typography>
        )}
      </DialogTitle>
      {behaviours && (
        <Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row">
              <Typography
                sx={{ margin: 2, fontWeight: "bold" }}
                variant="h6"
                component="div"
              >
                Behaviour List
              </Typography>
              <Chip
                avatar={
                  <Avatar>
                    <AddCircleIcon />
                  </Avatar>
                }
                label="Add new behaviour control"
                onClick={handleAdd}
                size="large"
                color="primary"
                sx={{ marginTop: 1.5 }}
              />
              {/* 
              <AddCircleIcon
                sx={{
                  marginTop: "20px",
                  color: "green",
                  fontSize: "30px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={handleAdd}
              /> */}
            </Stack>
            <List dense={false}>
              {behaviours.map((behaviour, idx) => {
                return (
                  <>
                    <ListItem
                      key={idx}
                      secondaryAction={
                        <div>
                          <IconButton aria-label="edit" edge="end">
                            <EditIcon onClick={() => handleEdit(idx)} />
                          </IconButton>
                          <IconButton aria-label="delete" edge="end">
                            <DeleteIcon onClick={() => handleDelete(idx)} />
                          </IconButton>
                        </div>
                      }
                    >
                      <ListItemText>{behaviour.behaviour}</ListItemText>
                    </ListItem>
                  </>
                );
              })}
            </List>
          </Grid>
        </Grid>
      )}
      {isAdding !== null && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant="h6" sx={{ margin: 2, fontWeight: "bold" }}>
            {isAdding ? "Adding new Behaviour" : "Editing a Behaviour"}
          </Typography>
          <DialogContent>
            <TextField
              name="behaviour"
              label="Behaviour"
              type="text"
              {...register("behaviour", {
                required: "Behaviour is required",
              })}
              error={!!errors.behaviourl}
              helperText={errors.behaviour?.message}
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </form>
      )}
    </div>
  );
}
