import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import Box from "@mui/material/Box";

// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";

export default function NotFound() {
  return (
    <>
      <Dialog open={true}>
        <DialogContent sx={{ margin: 2, padding: 1 }}>
          <Typography variant="h3">404 - Not Found</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
