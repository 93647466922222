import { useState, useEffect, useContext } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
// import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
// import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Chip from "@mui/material/Chip";

// import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
// import Container from "@mui/material/Container";

// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";

// import FormControl from "@mui/material/FormControl";
// import ResponsiveAppBar from "./ResponsiveAppBar";

import { LoginContext } from "../context/LoginContext";

import Detail from "./Detail";
// import AddDetail from "./AddDetail";

export default function Direct(category) {
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const { token } = useContext(LoginContext);

  const form = useForm({
    defaultValues: {
      indirectControl: "",
    },
  });
  const { register, handleSubmit, formState, watch, getValues, setValue } =
    form;
  const { errors } = formState;

  // const [open, setOpen] = useState(true);
  // const [categories, setCategories] = useState([]);
  const [indirects, setIndirects] = useState([]);
  const [isAdding, setIsAdding] = useState(null); //defaults to Adding
  const [index, setIndex] = useState(null);
  const [isAddingItem, setIsAddingItem] = useState(null);

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  // const navigate = useNavigate();

  // console.log("category ", category);
  // console.log("category id", category.category.id);

  useEffect(() => {
    // console.log("category ", category);
    // console.log("category id", category.id);
    const getDirects = async () => {
      try {
        await axios
          .get(`/api/sc/indirect/${category.category.id}`)
          .then((data) => {
            // console.log(data.data);
            setIndirects(data.data);
          });
      } catch (error) {
        console.log(error);
      }
    };
    getDirects();
  }, [category]);

  //this useEffect's purpose is just to refresh the screen when the delete or save button is click
  useEffect(() => {
    // console.log(indirects);
  }, [indirects, category]);

  const onSubmit = async (data) => {
    // data.categoryId = category.id;
    // data.category = category;
    let newData = {
      ...data,
      categoryId: category.category.id,
      category: category.category.category,
      projectId: token.projectId,
    };
    // console.log("new data: ", newData);
    // console.log(data);
    if (isAdding) {
      try {
        await axios.post("/api/sc/indirect", newData).then((data) => {
          // console.log("server response: ", data);
          setIndirects([...indirects, data.data.data]);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      //Editing here
      if (index >= 0) {
        const id = indirects[index]._id;
        try {
          await axios.post(`/api/sc/indirect/${id}`, data).then((data) => {
            // console.log("server response: ", data);

            const nextindirects = indirects.map((d, i) => {
              if (i === index) {
                // Replace the edited element
                d.indirectControl = getValues("indirectControl");
                return d;
              } else {
                // The rest haven't changed
                return d;
              }
            });
            setIndirects(nextindirects);
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    setIsAdding(null);
  };

  const handleClose = () => {
    setIsAdding(null);
  };

  const handleDelete = async (idx) => {
    setIndirects(
      indirects.filter((d) => {
        return d._id !== indirects[idx]._id;
      })
    );
    if (indirects[idx]._id) {
      try {
        await axios
          .delete(`api/sc/indirect/${indirects[idx]._id}`)
          .then((d) => {
            // console.log(d);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      // console.log(indirects[idx], idx);
    }
  };
  const handleEdit = async (idx) => {
    setIsAdding(false);
    setIndex(idx);
    setValue("indirectControl", indirects[idx].indirectControl);
  };

  const handleAdd = () => {
    setIsAdding(true);
  };

  const handleItem = (idx) => {
    setIsAddingItem(true);
    setIndex(idx);
    // console.log(`handle item + clicked: ${idx}`);
  };

  return (
    <div style={{ marginLeft: "50px" }}>
      <DialogTitle>
        <Typography
          sx={{ fontWeight: "bold", textAlign: "center" }}
          variant="h4"
        >
          standard control updates
        </Typography>
        {category.category.id && (
          <Typography sx={{ textAlign: "center" }} variant="h6">
            {category.category.category}
          </Typography>
        )}
      </DialogTitle>
      {indirects && (
        <>
          <Stack direction="row">
            <Typography
              sx={{ margin: 2, fontWeight: "bold" }}
              variant="h6"
              component="div"
            >
              Standard Control List
            </Typography>
            <Chip
              avatar={
                <Avatar>
                  <AddCircleIcon />
                </Avatar>
              }
              label="Add new standard control"
              onClick={handleAdd}
              size="large"
              color="primary"
              sx={{ marginTop: 1.5 }}
            />
          </Stack>
          <List dense={false}>
            {indirects.map((indirect, idx) => {
              return (
                <>
                  <ListItem
                    key={idx}
                    secondaryAction={
                      <div>
                        <IconButton aria-label="edit" edge="end">
                          <EditIcon onClick={() => handleEdit(idx)} />
                        </IconButton>
                        <IconButton aria-label="delete" edge="end">
                          <DeleteIcon onClick={() => handleDelete(idx)} />
                        </IconButton>
                      </div>
                    }
                  >
                    <ListItemText>
                      <Typography sx={{ fontWeight: "bold" }}>{`${idx + 1}. ${
                        indirect.indirectControl
                      }`}</Typography>
                    </ListItemText>
                  </ListItem>
                  <Chip
                    avatar={
                      <Avatar>
                        <AddCircleIcon />
                      </Avatar>
                    }
                    label="Add detailed control"
                    onClick={() => handleItem(idx)}
                    size="large"
                    color="secondary"
                    variant="outlined"
                    sx={{ marginTop: 1.5 }}
                  />

                  <ListItem key={idx + 1}>
                    <Detail
                      control={indirect}
                      setIsAddingItem={setIsAddingItem}
                      isAddingItem={isAddingItem}
                      currIndex={idx}
                      addItemIndex={index}
                    />
                  </ListItem>
                </>
              );
            })}
          </List>
        </>
      )}
      {isAdding !== null && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant="h6" sx={{ margin: 2, fontWeight: "bold" }}>
            {isAdding
              ? "Adding new Standard Control"
              : "Editing a Standard Control"}
          </Typography>
          <DialogContent>
            <TextField
              name="indirectControl"
              label="Indirect Control"
              type="text"
              {...register("indirectControl", {
                required: "Standard Control is required",
              })}
              error={!!errors.indirectControl}
              helperText={errors.indirectControl?.message}
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </form>
      )}
    </div>
  );
}
