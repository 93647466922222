// import { useState, useEffect, useContext } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export default function AddDetail({
  control,
  setIsAddingItem,
  setDetails,
  details,
}) {
  console.log("control to be added: ", control);
  console.log("details: ", details);

  const form = useForm({
    defaultValues: {
      detailDescription: "",
    },
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const onSubmit = async (data) => {
    let newData = {
      ...data,
      controlId: control._id, //this is coming from the passed parameter - control
      controlDescription: control.directControl, //this is coming from the passed parameter - control
      categoryId: control.categoryId, //this is coming from the passed parameter - category
      category: control.category, //this is coming from the passed parameter - category
      projectId: control.projectId, //this is coming from the LoginContext
    };
    // console.log("new data: ", newData);
    // console.log(data);
    try {
      await axios.post("/api/sc/detail", newData).then((data) => {
        // console.log("server response: ", data);
        // setAddedRecord(data.data);
        setDetails([...details, data.data.data]);
      });
    } catch (error) {
      console.log(error);
    }
    setIsAddingItem(null);
  };

  const handleCancel = () => {
    setIsAddingItem(null);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Typography sx={{ fontWeight: "bold" }}>
          Adding new detail Control
        </Typography>
        <DialogContent>
          <TextField
            name="detailDescription"
            label="Detail Description"
            type="text"
            {...register("detailDescription", {
              required: "Detail Description is required",
            })}
            error={!!errors.detailDescription}
            helperText={errors.detailDescription?.message}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button type="submit" variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}
