import { useState } from "react";
import { useCookies } from "react-cookie";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
// import Stack from "@mui/material/Stack";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";

import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { useLoginContext } from "../hooks/useLoginContext";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

function ResponsiveAppBar() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [cookies, removeCookie] = useCookies([]);

  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const { token, dispatch } = useLoginContext();

  //crude way to limit access - I will be the admin for the time being -- 04-Nov-2024
  const settings =
    token.email === "gilberto.gabon@builtenvirons.co.nz"
      ? [
          "Archived START Cards",
          "Category Update",
          "Location Update",
          "Hazards and Controls Update",
          "Project Update",
          "Generate Project QR Code",
          "Logout",
        ]
      : ["Archived START Cards", "Location Update", "Logout"];

  const handleCloseUserMenu = (e) => {
    setAnchorElUser(null);
    // console.log(e.target.innerText);
    // "Company", "Logout
    switch (e.target.innerText) {
      case "Archived START Cards":
        navigate(`/archive/${token.projectId}`);
        break;
      case "Category Update":
        navigate("/category");
        break;
      case "Location Update":
        navigate("/location");
        break;
      case "Hazards and Controls Update":
        navigate("/hazcontrol");
        break;
      case "Project Update":
        navigate("/project");
        break;
      case "Generate Project QR Code":
        navigate("/projectqrcode");
        break;
      case "Logout":
        // doLogOut();
        removeCookie("token");
        dispatch({ type: "LOGOUT", payload: null });
      // navigate("/logout");

      default:
        navigate("/dashboard");
    }
  };

  const goHome = (e) => {
    // console.log(e);
    navigate(`/dashboard`);
    // navigate("/category");
  };

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" color="primary">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                m: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: "bold",
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={goHome}
            >
              {`START Card: ${token.project.label}`}
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                m: 2,
                display: { xs: "flex", md: "none" },
                fontWeight: "bold",
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={goHome}
            >
              {`START Card`}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ display: { xs: "none", md: "flex" } }}>
                  {token.email}
                </Typography>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mx: 2 }}>
                    <FontAwesomeIcon
                      icon={faBars}
                      className="text-warning"
                      style={{
                        fontSize: "30px",
                        margin: "5px",
                        cursor: "pointer",
                        float: "left",
                        color: "white",
                      }}
                      // onClick={handleShow}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px", mr: "5px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Box>
        </AppBar>
      </ThemeProvider>
    </>
  );
}
export default ResponsiveAppBar;
