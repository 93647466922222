import { createContext, useReducer } from "react";

export const AttendeesContext = createContext();

export const attendeesReducer = (state, action) => {
  switch (action.type) {
    case "SET_ATTENDEES":
      return {
        employees: action.payload,
      };

    case "UPDATE_ATTENDEES":
      return {
        employees: [...state.employees],
      };

    default:
      return state;
  }
};

export const AttendeesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(attendeesReducer, {
    employees: null,
  });

  return (
    <AttendeesContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AttendeesContext.Provider>
  );
};
