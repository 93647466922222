import { Chart, defaults } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";

import Container from "@mui/material/Container";
// import Card from "@mui/material/Card";

Chart.register(CategoryScale);

defaults.responsive = true;
defaults.maintainAspectRatio = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "center";
defaults.plugins.title.color = "black";
defaults.plugins.title.font.size = 20;
const BarChartByGoldenRules = ({ data }) => {
  return (
    <Container maxWidth="xl">
      <div style={{ width: "100%", height: "20%" }}>
        <Bar
          data={data}
          options={{
            plugins: {
              title: {
                text: "By Golden Rules",
              },
            },
            indexAxis: "y",
          }}
        />
      </div>
    </Container>
  );
};
export default BarChartByGoldenRules;
