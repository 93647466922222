import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SignaturePad from "./SignaturePad";

import { useAttendeesContext } from "../hooks/useAttendeesContext";

export default function ListAttend({ submitted }) {
  const { employees } = useAttendeesContext();

  const [onSign, setOnSign] = useState(false);
  const [idx, setIdx] = useState(null);
  const [doneSign, setDoneSign] = useState(false);

  const handleClick = (idx, e) => {
    setOnSign(true);
    setIdx(idx);
  };
  return (
    <>
      <Typography variant="h4" sx={{ margin: 2 }}>
        Attendees
      </Typography>

      <Grid container sx={{ mx: 1 }}>
        {employees.length > 0 &&
          employees.map((item, i) => {
            return (
              <Grid key={i}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ margin: 1 }}
                  onClick={(e) => handleClick(i, e)}
                  disabled={item.isSigned || submitted}
                >
                  {item.name}
                </Button>

                {item.imgUrl && (
                  <Grid>
                    <Box>
                      <img width="100" height="50" src={item.imgUrl} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            );
          })}
        {onSign && (
          <SignaturePad
            setOnSign={setOnSign}
            setIdx={setIdx}
            setDoneSign={setDoneSign}
            idx={idx}
            name={employees[idx].name}
          />
        )}
      </Grid>
    </>
  );
}
