import { useState, useEffect } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLoginContext } from "../hooks/useLoginContext";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
// import FolderIcon from "@mui/icons-material/Folder";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import AddCircleIcon from "@mui/icons-material/AddCircle";

// import Grid from "@mui/material/Unstable_Grid2";
// import Stack from "@mui/material/Stack";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import FormControl from "@mui/material/FormControl";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { FormInputDropdownSetup } from "./form-components/FormInputDropdownSetup";

export default function ProjectSetup() {
  const { token } = useLoginContext();

  const form = useForm({
    defaultValues: {
      projectId: "",
    },
  });
  const { register, handleSubmit, formState, control, getValues } = form;
  const { errors } = formState;

  const [open, setOpen] = useState(true);
  const [msg, setMsg] = useState("");

  const [projects, setProjects] = useState([]);

  const [projectId, setProjectId] = useState("");
  const [projectname, setProjectName] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const navigate = useNavigate();

  useEffect(() => {
    const getProjects = async () => {
      const projects = await axios.get("/api/sc/getprojects");
      // console.log("Projects: ", projects.data);
      // localStorage.setItem("projects", JSON.stringify(projects.data));
      const projectsNew = projects.data[0].filter((p) => {
        return p.projectId !== token.projectId; //remove 4068 - Te Whatu Ora as this is the reference project - we do not want duplicating its records in the DB
      });

      try {
        const projectsData = projectsNew.map((project) => {
          return {
            value: project.projectId,
            label: project.name,
          };
        });
        //sort projects by name
        projectsData.sort((a, b) => {
          let fa = a.label.toLowerCase(),
            fb = b.label.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });

        setProjects(projectsData);
      } catch (err) {
        console.log(err);
      }
    };
    getProjects();
  }, []);

  const onSubmit = async (data) => {
    console.log("data: ", data);
    // console.log("projectId variable: ", getValues("projectId"));
    const projectName = projects.filter((p) => {
      return p.value === data.projectId;
    });

    console.log("Project name: ", projectName[0].label);
    data.projectName = projectName[0].label;
    data.hostProjectId = token.projectId;
    console.log("data_new: ", data);

    try {
      await axios.post("/api/sc/project", data).then((data) => {
        console.log("server response: ", data);
        setMsg("Project successfully setup");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    navigate("/dashboard", { replace: true });
    setOpen(false);
  };

  return (
    <div>
      <ResponsiveAppBar />
      {projects && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={fullScreen}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Typography
              sx={{ fontWeight: "bold", textAlign: "center" }}
              variant="h4"
            >
              project setup
            </Typography>
            {msg && (
              <Typography
                sx={{ textAlign: "center", fontSize: 12, color: "blue" }}
              >
                {msg}
              </Typography>
            )}
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <DialogContent sx={{ margin: 2, padding: 1 }}>
              <FormInputDropdownSetup
                name="projectId"
                control={control}
                label="Project"
                options={projects}
                {...register("projectId", { required: true })}
                getValues={getValues}
                setProjectId={setProjectId}
                setProjectName={setProjectName}
              />
              {errors.projectId && (
                <Typography variant="caption" sx={{ color: "red" }}>
                  Select Project
                </Typography>
              )}
            </DialogContent>
            <DialogContent>
              <TextField
                name="fullName"
                label="Fullname"
                type="text"
                {...register("fullName", {
                  required: "Fullname is required",
                })}
                error={!!errors.fullName}
                helperText={errors.fullName?.message}
                fullWidth
                required
              />
            </DialogContent>
            <DialogContent sx={{ margin: 2, padding: 1 }}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  name="email"
                  label="email"
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Not a valid email",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  required
                />
              </FormControl>
            </DialogContent>

            <DialogContent sx={{ margin: 2, padding: 1 }}>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </DialogContent>
          </form>
        </Dialog>
      )}
    </div>
  );
}
