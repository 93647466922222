import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import {
  Container,
  DialogContent,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CircularProgress from "@mui/material/CircularProgress";

// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import ShowGoldenRule from "./ShowGoldenRule";
import AddSignaturePad from "./AddSignaturePad";
import UpdateCondition from "./UpdateCondition";

function TextWithLineBreaks(props) {
  const textWithBreaks = props.text.split("\n").map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));

  return <div>{textWithBreaks}</div>;
}

function StartCardUrl() {
  const { id } = useParams();
  // console.log("params.id: ", id);
  const highRiskWorks = [
    {
      label: "Working at Height > 2m",
      value: "1",
    },
    {
      label: "Demolition of load bearing structure",
      value: "2",
    },
    {
      label: "Likely to involve asbestos",
      value: "3",
    },
    {
      label: "Temporary Load Bearing support",
      value: "4",
    },
    {
      label: "On/Near Electrical installations",
      value: "5",
    },
    {
      label: "Area with a contaminated atmosphere",
      value: "6",
    },
    {
      label: "Tilt-up/Pre-cast concrete elements",
      value: "7",
    },
    {
      label: "Adjacent to Road or Railway line",
      value: "8",
    },
    {
      label: "Work in/near a Confined Space",
      value: "9",
    },
    {
      label: "Work in/near a Trench > 1.5m",
      value: "10",
    },
    {
      label: "Work on/near pressurised Gas",
      value: "11",
    },
    {
      label: "Work on/near Chemical or Fuel Lines",
      value: "12",
    },
    {
      label: "Area with Powered Mobile Plant",
      value: "13",
    },
    {
      label: "Areas with artificial Extremes of temperatures",
      value: "14",
    },
    {
      label: "Near water or liquid where there is a risk of Drowning",
      value: "15",
    },
    {
      label: "No High Risk Construction Work applies",
      value: "0",
    },
  ];

  const [startcard, setStartCard] = useState(null);
  const [projects, setProjects] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [hrcw, setHrcw] = useState([]);
  const [doSign, setDoSign] = useState(false);
  const [updateConditions, setUpdateConditions] = useState(false);
  const [showConditions, setShowConditions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [oldStyle, setOldStyle] = useState(false);

  // console.log("id: ", id);
  useEffect(() => {
    setIsLoading(true);
    const getStartCard = async () => {
      try {
        await axios.get(`/api/sc/getstartcard/${id}`).then((data) => {
          // console.log(data.data);
          console.log(data.data.controls.length);

          //added: 04-Dec-2024 due to allowing the addition of text in the list of controls
          if (data.data.controls.length > 1) {
            setOldStyle(true);
          }

          setStartCard(data.data);
          const hrcwText = data.data.hrcw.map((h) => {
            const label = highRiskWorks.filter((hw) => {
              // console.log(typeof h);
              // console.log(typeof hw.value);
              // console.log("h: ", h);
              // console.log("hw.value: ", hw.value);
              // console.log(h.trim() === hw.value.trim());
              return parseInt(h) === parseInt(hw.value);
            });
            // console.log("label: ", label);
            return label;
          });
          // console.log(hrcwText);
          setHrcw(hrcwText);
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getProjects = async () => {
      await axios.get("/api/sc/getprojects").then((response) => {
        // console.log(response.data[0]);
        setProjects(response.data[0]);
      });
    };
    getProjects();
    getStartCard();
    setIsLoading(false);
  }, [updateConditions]);

  const handleSignClick = () => {
    setDoSign(true);
  };

  const handleConditionClick = () => {
    // setUpdateConditions(true);
    setShowConditions(true);
  };

  return (
    <>
      <Container>
        {isLoading && <CircularProgress />}
        <Stack
          sx={{ margin: 2, backgroundColor: "green", borderRadius: "25px" }}
        >
          <Typography
            sx={{
              color: "white",
              padding: 1,
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            S.T.A.R.T Card
          </Typography>
        </Stack>
        {startcard && (
          <Stack>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography sx={{ margin: 1 }}>Date</Typography>
              <Typography sx={{ margin: 1, fontWeight: "bold" }}>
                {moment(startcard.workDate).format("DD-MMM-YYYY HH:MM:SS A")}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography sx={{ margin: 1 }}>Project</Typography>
              <Typography sx={{ margin: 1, fontWeight: "bold" }}>
                {startcard.projectName}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography sx={{ margin: 1 }}>Employer</Typography>
              <Typography sx={{ margin: 1, fontWeight: "bold" }}>
                {startcard.employerName}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography sx={{ margin: 1 }}>Location</Typography>
              <Typography sx={{ margin: 1, fontWeight: "bold" }}>
                {startcard.location}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography sx={{ margin: 1 }}>Task:</Typography>
              <Typography sx={{ margin: 1, fontWeight: "bold" }}>
                {startcard.task}
              </Typography>
            </Stack>
            <Stack sx={{ margin: 2, backgroundColor: "purple" }}>
              <Typography
                sx={{
                  color: "white",
                  padding: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                STEP 1 - STOP : Identify High Risk Construction Work
              </Typography>
            </Stack>
            {hrcw.length > 0 && (
              <DialogContent
                sx={{
                  margin: 1,
                  padding: 1,
                }}
              >
                {hrcw.map((h, idx) => {
                  return (
                    <>
                      <ListItemText key={idx}>
                        {idx + 1}. {h[0].label}
                      </ListItemText>
                    </>
                  );
                })}
              </DialogContent>
            )}
            <Stack sx={{ margin: 2, backgroundColor: "red" }}>
              <Typography
                sx={{
                  color: "white",
                  padding: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                STEP 2 - THINK : Complete this START Card at your work location
                / area
              </Typography>
            </Stack>
            <Stack sx={{ margin: 2 }}>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography>
                  1. All required permits to work (P2W) have been approved and
                  signed off
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {startcard.step1.toUpperCase()}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography>
                  2. Plant operators hold the relevant license and Plant
                  Operators Assessment
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {startcard.step2.toUpperCase()}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography>
                  3. Correct tools/equipment and PPE are available
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {startcard.step3.toUpperCase()}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography>
                  4. SWMS have been read and understood for all High Risk
                  Construction Work
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {startcard.step4.toUpperCase()}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography>
                  5. Exclusion zones required to protect others have been
                  established
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {startcard.step5.toUpperCase()}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography>
                  6. If required, a rescue/retrieval plan has been developed and
                  approved
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {startcard.step6.toUpperCase()}
                </Typography>
              </Stack>
            </Stack>

            <Stack sx={{ margin: 2, backgroundColor: "orange" }}>
              <Typography
                sx={{
                  color: "white",
                  padding: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                STEP 3 - ASSESS : Discuss the hazards and controls with the
                group
              </Typography>
            </Stack>

            <Stack sx={{ margin: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>GOLDEN RULES</Typography>
            </Stack>
            <Grid container spacing={2} sx={{ margin: 2 }}>
              <ShowGoldenRule goldenRule={startcard.categories} />
            </Grid>
            <Stack sx={{ margin: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>CONTROLS</Typography>
            </Stack>
            <Stack sx={{ margin: 2 }}>
              {oldStyle ? (
                <div>
                  {/* {`old: ${oldStyle}`} */}
                  <ul>
                    {startcard.controls.length > 0 &&
                      startcard.controls.map((h) => {
                        {
                          if (Array.isArray(h)) {
                            return h.map((h) => {
                              return (
                                <>
                                  <li style={{ marginLeft: "30px" }}>
                                    {h.description}
                                  </li>
                                </>
                              );
                            });
                          }
                        }
                      })}
                  </ul>
                </div>
              ) : (
                <div>
                  {/* {`new: ${oldStyle}`} */}
                  <TextWithLineBreaks text={startcard.controls.toString()} />
                </div>
              )}
              {/* <div>{startcard.controls.toString()}</div> */}
            </Stack>

            <Stack sx={{ margin: 2, backgroundColor: "lightgreen" }}>
              <Typography
                sx={{
                  color: "white",
                  padding: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                STEP 4 - REVIEW : Monitor conditions and check for change
              </Typography>
            </Stack>

            <Stack sx={{ margin: 2 }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  CHANGE IN CONDITIONS
                </Typography>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ padding: 1 }}
                  onClick={handleConditionClick}
                >
                  Update
                </Button>
              </Stack>
              <Typography sx={{ padding: 1 }}>
                {startcard.conditions}
              </Typography>
            </Stack>
            {showConditions && (
              <UpdateCondition
                setUpdateConditions={setUpdateConditions}
                setStartCard={setStartCard}
                startcard={startcard}
                setIsLoading={setIsLoading}
                setShowConditions={setShowConditions}
              />
            )}
            <Stack sx={{ margin: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                CHANGE IN MANAGEMENT
              </Typography>
              <Typography sx={{ padding: 1 }}>
                {startcard.management}
              </Typography>
            </Stack>

            <Stack sx={{ margin: 2, backgroundColor: "green" }}>
              <Typography
                sx={{
                  color: "white",
                  padding: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                STEP 5 - TALK : Review, agree and sign on
              </Typography>
            </Stack>
            <Typography variant="h6" sx={{ fontWeight: "bold", mx: 2 }}>
              Photo
            </Typography>
            <DialogContent sx={{ margin: 2, padding: 1 }}>
              <Stack>
                <img
                  src={`/api/sc/image/${id}/${startcard.fname}`}
                  style={{
                    width: "300px",
                    height: "300px",
                    marginTop: "10px",
                    boxShadow: "5px 5px 5px lightgray",
                  }}
                  alt="preview"
                />
              </Stack>
            </DialogContent>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", mx: 2 }}>
                Signatures
              </Typography>
              <Button
                variant="contained"
                size="medium"
                sx={{ padding: 1 }}
                onClick={handleSignClick}
              >
                Add signatures
              </Button>
            </Stack>
            <Stack>
              {doSign && (
                <AddSignaturePad
                  setDoSign={setDoSign}
                  setStartCard={setStartCard}
                  startcard={startcard}
                />
              )}
            </Stack>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {startcard.workers.map((h) => {
                return (
                  <>
                    <Grid item xs={4}>
                      <ListItemText>{h.name}</ListItemText>
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          marginTop: "10px",
                          boxShadow: "5px 5px 5px lightgray",
                        }}
                        src={h.imgUrl}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Stack>
        )}
      </Container>
    </>
  );
}
export default StartCardUrl;
