import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";
// import axios from "axios";

// const options = [
//   {
//     value: "{Access and Egress}",
//     label: "{Access and Egress}",
//   },
//   {
//     value: "{Approvals/Permits}",
//     label: "{Approvals/Permits}",
//   },
//   {
//     value: "{Climatic / Natural Events}",
//     label: "{Climatic / Natural Events}",
//   },
//   {
//     value: "{Communication}",
//     label: "{Communication}",
//   },
// ];

export const FormInputDropdownSetup = ({
  name,
  control,
  label,
  options,
  isDisabled,
  getValues,
  setProjectId,
  setProjectName,
  setUrl,
}) => {
  console.log("label: ", label);
  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const changeHandler = async () => {
    const projectId = getValues(name); //this is from react-hook-form --> StatCard.js
    const projectName = options.filter((o) => {
      return o.value === projectId;
    });
    console.log("projectId: ", projectId);
    console.log("projectName: ", projectName[0].label);
    setProjectId(projectId);
    setProjectName(projectName[0].label);
    setUrl(`${window.location.protocol}//${window.location.host}/${projectId}`);
  };

  return (
    // <FormControl size={"small"} fullWidth sx={{ marginBottom: "10px" }}>
    //   <FormLabel>{label}</FormLabel>
    //   <Controller
    //     render={({ field: { onChange, value } }) => (
    //       <Select onChange={onChange} value={value} defaultValue="">
    //         {generateSingleOptions()}
    //       </Select>
    //     )}
    //     control={control}
    //     name={name}
    //   />
    // </FormControl>
    <FormControl size={"small"} fullWidth sx={{ marginBottom: "10px" }}>
      <FormLabel>{label}</FormLabel>
      <Controller
        render={({ field: { onChange: rhfOnChange, value } }) => (
          <Select
            onChange={(ev) => {
              const {
                target: { value },
              } = ev;
              rhfOnChange(value);
              changeHandler();
              console.log("ev: ", ev);
              console.log("value: ", value);
              console.log("label: ", label);
            }}
            value={value}
            disabled={isDisabled}
            defaultValue=""
          >
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
