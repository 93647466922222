import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";
import axios from "axios";
import { useAttendeesContext } from "../../hooks/useAttendeesContext";

export const FormInputDropdownCustom = ({
  name,
  control,
  label,
  options,
  isDisabled,
  getValues,
  setIsLoadingAttendees,
  projectId,
  setCompleted,
  completed,
}) => {
  const { dispatch } = useAttendeesContext();

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  // console.log(typeof getValues);
  //  `/api/sc/getsignin/59450841-3939-ed11-8450-b07b25c00deb/${employerId}`
  const changeHandler = async () => {
    setIsLoadingAttendees(true);
    const employerId = getValues(name); //this is from react-hook-form --> StatCard.js
    // const employerId = "3d34e418-c545-ed11-8450-b07b25c00deb"; //4068 - Counties Manukau Health
    // console.log(employerId);

    //update the 'complete' array for the Submit button
    if (employerId.length > 0) {
      setCompleted([...completed, { employer: true }]);
    }

    try {
      const response = await axios.get(
        `/api/sc/getsignin/${projectId}/${employerId}`
      );
      // console.log("response.data: ", response.data);

      let employeeList = [];
      employeeList = response.data;
      employeeList.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const finalList = employeeList.map((item) => {
        return { ...item, idx: 0, imgUrl: "", isSigned: false };
      });

      dispatch({ type: "SET_ATTENDEES", payload: finalList }); //using 'context' to manage these data on employees/attendees: 10-Sept-2024
    } catch (error) {
      console.log(error);
    }
    setIsLoadingAttendees(false);
  };

  return (
    <FormControl size={"small"} fullWidth sx={{ marginBottom: "10px" }}>
      <FormLabel>{label}</FormLabel>
      <Controller
        // Custom onChange handler implemented here: 07-Sept-2024
        render={({ field: { onChange: rhfOnChange, value } }) => (
          <Select
            onChange={(ev) => {
              const {
                target: { value },
              } = ev;
              rhfOnChange(value);
              changeHandler();
            }}
            value={value}
            disabled={isDisabled}
            defaultValue=""
          >
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
