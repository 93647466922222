import { createContext, useReducer } from "react";
export const LoginContext = createContext({});

export const loginReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return {
        token: action.payload,
      };
    case "LOGOUT":
      // removeCookie("token"), navigate("/login");
      return {
        token: action.payload,
      };

    default:
      return state;
  }
};

export const LoginContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(loginReducer, {
    token: null,
  });

  return (
    <LoginContext.Provider value={{ ...state, dispatch }}>
      {children}
    </LoginContext.Provider>
  );
};

// import { createContext, useReducer } from "react";

// export const AuthContext = createContext();

// export const authReducer = (state, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       return { user: action.payload };

//     case "LOGOUT":
//       return { user: action.payload };
//     case "UPDATE":
//       return { user: action.payload };
//     default:
//       return state;
//   }
// };

// export const AuthContextProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(authReducer, {
//     user: null,
//   });
//   // console.log("AuthContext state: ", state);
//   return (
//     <AuthContext.Provider value={{ ...state, dispatch }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
