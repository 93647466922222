import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Container } from "@mui/material";

import { Controller } from "react-hook-form";

export const FormInputRadio = ({
  name,
  control,
  label,
  options,
  isDisabled,
}) => {
  const generateRadioOptions = () => {
    return options.map((singleOption, idx) => (
      <FormControlLabel
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio sx={{ margin: 0, padding: "2px" }} />}
        key={idx}
        sx={{ margin: 1 }}
        disabled={isDisabled}
      />
    ));
  };
  // <FormLabel labelPlacement="start" component="legend">
  return (
    <Container maxWidth="xl">
      <FormControl component="fieldset" sx={{ mx: 1 }}>
        <FormLabel component="legend">{label}</FormLabel>

        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <RadioGroup row value={value} onChange={onChange}>
              {generateRadioOptions()}
            </RadioGroup>
          )}
        />
      </FormControl>
    </Container>
  );
};
