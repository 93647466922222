import { useState, useEffect, useContext } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
// import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import FormControl from "@mui/material/FormControl";
import ResponsiveAppBar from "./ResponsiveAppBar";

// import { LoginContext } from "../context/LoginContext";

import { useLoginContext } from "../hooks/useLoginContext";

export default function Location() {
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  // const { token } = useContext(LoginContext);
  //  const { token, dispatch } = useContext(LoginContext);
  const { token } = useLoginContext();

  const form = useForm({
    defaultValues: {
      location: "",
    },
  });
  const { register, handleSubmit, formState, getValues, setValue } = form;
  const { errors } = formState;

  const [open, setOpen] = useState(true);
  const [locations, setLocations] = useState([]);
  const [isAdding, setIsAdding] = useState(null); //defaults to Adding
  const [index, setIndex] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const navigate = useNavigate();

  useEffect(() => {
    const getLocations = async () => {
      try {
        await axios.get(`/api/sc/locations/${token.projectId}`).then((data) => {
          // console.log(data.data);
          setLocations(data.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getLocations(); //This is the right way to call a useEffect function --> React gives a warning if this is not the case
  }, []);

  //this useEffect's purpose is just to refresh the screen when the delete or save button is click
  useEffect(() => {
    // console.log(locations);
  }, [locations]);

  const onSubmit = async (data) => {
    let newData = {
      ...data,
      projectId: token.projectId,
    };
    if (isAdding) {
      try {
        await axios.post("/api/sc/location", newData).then((data) => {
          // console.log("server response: ", data);
          setLocations([...locations, data.data.data]);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      //Editing here
      // console.log("Editing: ", index);
      if (index >= 0) {
        // console.log("Editing: ", index);
        const id = locations[index]._id;
        try {
          await axios.post(`/api/sc/location/${id}`, data).then((data) => {
            // console.log("server response: ", data);

            const nextLocations = locations.map((l, i) => {
              if (i === index) {
                // Replace the edited element
                l.location = getValues("location");

                return l;
              } else {
                // The rest haven't changed
                return l;
              }
            });
            setLocations(nextLocations);
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    setIsAdding(null);
  };

  const handleClose = () => {
    navigate("/dashboard", { replace: true });
    setOpen(false);
  };

  const handleDelete = async (idx) => {
    setLocations(
      locations.filter((c) => {
        return c._id !== locations[idx]._id;
      })
    );
    if (locations[idx]._id) {
      try {
        await axios
          .delete(`api/sc/location/${locations[idx]._id}`)
          .then((d) => {
            // console.log(d);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      // console.log(locations[idx], idx);
    }
  };
  const handleEdit = async (idx) => {
    // console.log("idx: ", idx);
    setIsAdding(false);
    setIndex(idx);
    setValue("location", locations[idx].location);
  };

  const handleAdd = () => {
    setIsAdding(true);
  };

  return (
    <div>
      <ResponsiveAppBar />
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography
            sx={{ fontWeight: "bold", textAlign: "center" }}
            variant="h4"
          >
            location updates
          </Typography>
        </DialogTitle>
        {locations && (
          <Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row">
                <Typography
                  sx={{ margin: 2, fontWeight: "bold" }}
                  variant="h6"
                  component="div"
                >
                  Location List
                </Typography>
                <AddCircleIcon
                  sx={{
                    marginTop: "20px",
                    color: "green",
                    fontSize: "30px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={handleAdd}
                />
              </Stack>
              <List dense={false}>
                {locations.map((location, idx) => {
                  return (
                    <>
                      <ListItem
                        key={idx}
                        secondaryAction={
                          <div>
                            <IconButton aria-label="edit">
                              <EditIcon onClick={() => handleEdit(idx)} />
                            </IconButton>
                            <IconButton aria-label="delete">
                              <DeleteIcon onClick={() => handleDelete(idx)} />
                            </IconButton>
                          </div>
                        }
                      >
                        <ListItemText>{location.location}</ListItemText>
                      </ListItem>
                    </>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        )}
        {isAdding !== null && (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Typography variant="h6" sx={{ margin: 2, fontWeight: "bold" }}>
              {isAdding ? "Adding new location" : "Editing a location"}
            </Typography>
            <DialogContent>
              <TextField
                name="location"
                label="Location"
                type="text"
                {...register("location", {
                  required: "location is required",
                })}
                error={!!errors.location}
                helperText={errors.location?.message}
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
}
