import { useState, useEffect } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
// import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import IconButton from "@mui/material/IconButton";
// import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import AddCircleIcon from "@mui/icons-material/AddCircle";

// import Chip from "@mui/material/Chip";
// import Avatar from "@mui/material/Avatar";

// import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
// import Container from "@mui/material/Container";

// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";

// import FormControl from "@mui/material/FormControl";
// import ResponsiveAppBar from "./ResponsiveAppBar";

// import { LoginContext } from "../context/LoginContext";

import AddDetail from "./AddDetail";

export default function Detail({
  control,
  setIsAddingItem,
  isAddingItem,
  currIndex,
  addItemIndex,
}) {
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  // console.log("control: ", control);

  const form = useForm({
    defaultValues: {
      detailDescription: control.detailDescription,
    },
  });
  const { register, handleSubmit, formState, getValues, setValue } = form;
  const { errors } = formState;

  const [details, setDetails] = useState([]);

  const [isEditing, setIsEditing] = useState(null);
  const [index, setIndex] = useState(null);

  useEffect(() => {
    const getDetails = async () => {
      try {
        await axios
          .get(
            `/api/sc/detail/${control.projectId}/${control.categoryId}/${control._id}`
          )
          .then((data) => {
            // console.log(data.data);
            setDetails(data.data);
          });
      } catch (error) {
        console.log(error);
      }
    };
    getDetails();
  }, [control]);

  //this useEffect's purpose is just to refresh the screen when the delete or save button is click
  useEffect(() => {
    // console.log("refresh: ", details);
  }, [details, control]);

  const onSubmit = async (data) => {
    //Editing here
    if (index >= 0) {
      const id = details[index]._id;
      try {
        await axios.post(`/api/sc/detail/${id}`, data).then((data) => {
          // console.log("server response: ", data);

          const nextdetails = details.map((d, i) => {
            if (i === index) {
              // Replace the edited element
              d.detailDescription = getValues("detailDescription");
              return d;
            } else {
              // The rest haven't changed
              return d;
            }
          });
          setDetails(nextdetails);
        });
      } catch (error) {
        console.log(error);
      }
    }
    setIsEditing(null);
  };

  const handleDelete = async (idx) => {
    setDetails(
      details.filter((d) => {
        return d._id !== details[idx]._id;
      })
    );
    if (details[idx]._id) {
      try {
        await axios.delete(`api/sc/detail/${details[idx]._id}`).then((d) => {
          // console.log(d);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      // console.log(details[idx], idx);
    }
  };
  const handleEdit = async (idx) => {
    setIndex(idx);
    setIsEditing(true);
    // console.log("Editing: ", details[idx].detailDescription);
    setValue("detailDescription", details[idx].detailDescription);
  };

  const handleCancel = () => {
    setIsEditing(null);
  };

  return (
    <Stack>
      <Stack>
        {details && (
          <List dense={false}>
            {details.map((detail, idx) => {
              return (
                <ListItem
                  key={idx}
                  secondaryAction={
                    <div>
                      <IconButton aria-label="edit" edge="end">
                        <EditIcon onClick={() => handleEdit(idx)} />
                      </IconButton>
                      <IconButton aria-label="delete" edge="end">
                        <DeleteIcon onClick={() => handleDelete(idx)} />
                      </IconButton>
                    </div>
                  }
                >
                  <ListItemText sx={{ marginRight: 5 }}>
                    {detail.detailDescription}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        )}
      </Stack>
      <Stack>
        {isAddingItem && currIndex === addItemIndex && (
          <div>
            <AddDetail
              control={control}
              setIsAddingItem={setIsAddingItem}
              setDetails={setDetails}
              details={details}
            />
          </div>
        )}
        {isEditing && (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Typography sx={{ fontWeight: "bold" }}>
              Editing a detailed Control
            </Typography>
            <DialogContent>
              <TextField
                name="detailDescription"
                label="Detail Description"
                type="text"
                {...register("detailDescription", {
                  required: "Detail Description is required",
                })}
                error={!!errors.detailDescription}
                helperText={errors.detailDescription?.message}
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Button type="submit" variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Stack>
    </Stack>
  );
}
