import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

const DisplayDetails = ({ controlId, details }) => {
  // console.log("ControlID: ", controlId);
  // console.log(details);
  if (details?.length > 0) {
    const detailList = details.filter((d) => {
      return d.controlId === controlId;
    });
    return (
      <>
        {detailList && (
          <ul>
            {detailList.map((item, idx) => {
              return (
                <li key={idx} style={{ fontStyle: "italic", margin: "20px" }}>
                  {item.detailDescription}
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  }
};

export const FormInputMultiCheckbox = ({
  name,
  control,
  setValue,
  label,
  options,
  isDisabled,
  details,
  setCompleted,
  completed,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  // we are handling the selection manually here
  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
    if (selectedItems.length > 0) {
      setCompleted([...completed, { hrcw: true }]);
    }
  };

  // we are setting form value manually here
  useEffect(() => {
    setValue(name, selectedItems);
  }, [name, selectedItems, setValue]);

  return (
    <FormControl size={"small"} variant={"outlined"}>
      <FormLabel component="legend" sx={{ fontWeight: "bold", color: "blue" }}>
        {label}
      </FormLabel>

      <div style={{ display: "flex", flexDirection: "column" }}>
        {options.map((option) => {
          return (
            <>
              <FormControlLabel
                control={
                  <Controller
                    name={name}
                    key={option.value}
                    render={() => {
                      return (
                        <Checkbox
                          checked={selectedItems.includes(option.value)}
                          onChange={() => handleSelect(option.value)}
                          disabled={isDisabled}
                        />
                      );
                    }}
                    control={control}
                  />
                }
                label={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {option.label}
                  </Typography>
                }
                key={option.value}
              />

              <DisplayDetails controlId={option.value} details={details} />
            </>
          );
        })}
      </div>
    </FormControl>
  );
};
