import { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

import axios from "axios";
import moment from "moment";

// import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Alert from "@mui/material/Alert";

import CircularProgress from "@mui/material/CircularProgress";
// import Snackbar from "@mui/material/Snackbar";
import ResponsiveAppBar from "./ResponsiveAppBar";
import FilterListIcon from "@mui/icons-material/FilterList";

// import { LoginContext } from "../context/LoginContext";
import { useLoginContext } from "../hooks/useLoginContext";

import Loading from "./Loading";
import ListStartCard from "./ListStartCard";
import PieChartByLocation from "./PieChartByLocation";
import BarChartByControls from "./BarChartByControls";
import BarChartByGoldenRules from "./BarChartByGoldenRules";
import LineChartByMonth from "./LineChartByMonth";
// import GoldenRulesData from "./GoldenRulesData";
// import CriticalControlData from "./CriticalControlData";
// import StartCardByMonthData from "./StartCardByMonth";

export default function Home({ socket }) {
  const { token } = useLoginContext();

  const navigate = useNavigate();

  // const [projects, setProjects] = useState([]);
  // const [version, setVersion] = useState(null);
  // const [employers, setEmployers] = useState([]); //when initial value is 'null', this causes error due to the map command which is expecting an array.
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [startcards, setStartCards] = useState([]);
  const [statusMsg, setStatusMsg] = useState("");
  const [scByLocation, setScByLocation] = useState([]);
  const [data, setData] = useState([]);
  const [daysdata, setDaysData] = useState([]);

  const [grdata, setGrData] = useState([]);
  const [ccdata, setCcData] = useState([]);
  const [scmonth, setScMonth] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showList, setShowList] = useState(false);

  const [dataset, setDataSet] = useState([]);
  // const Data = [...StartCardByMonthData]; //dummy data for the monthly stats

  const [startDate, setStartDate] = useState(new Date()); //default to today's date
  const [endDate, setEndDate] = useState(new Date()); //default to today's date

  const [cookies, removeCookie] = useCookies([]);

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }
  useEffect(() => {
    // const verifyCookie = async () => {
    //   if (!cookies.token) {
    //     navigate("/login");
    //   }
    //   const { data } = await axios.post(
    //     "http://localhost:4000",
    //     {},
    //     { withCredentials: true }
    //   );
    //   const { status, user } = data;
    //   setUsername(user);
    //   return status
    //     ? toast(`Hello ${user}`, {
    //         position: "top-right",
    //       })
    //     : (removeCookie("token"), navigate("/login"));
    // };

    const getStartCards = async () => {
      try {
        // await axios.get("/api/sc/sccount").then((data) => {
        // await axios.get(`/api/sc/sccount/${token.projectId}`).then((data) => {
        setIsLoading(true);
        // if (!cookies.token) {
        //   navigate("/login");
        // }
        // console.log("cookies.token: ", cookies.token);
        await axios
          .get(
            `/api/sc/sccount/${token.projectId}/${moment(startDate).format(
              "YYYY-MM-DD"
            )}/${moment(endDate).format("YYYY-MM-DD")}`
          ) //implemented: 12-Nov-2024
          .then((data) => {
            // console.log(data.data);
            setStartCards(data.data.total);
            setScByLocation(data.data.bylocation);
            setMessage(`${data.data.total.length}`);

            //Pie Chart by Location
            const piedata = {
              labels: data.data.bylocation?.map((d) => d.location),
              datasets: [
                {
                  label: "START Cards",
                  data: data.data.bylocation?.map((d) => d.count),
                  borderRadius: 10,
                },
              ],
            };
            // console.log("piedata: ", piedata);
            if (!isEmpty(piedata)) {
              setDataSet(piedata);
            }

            // //Golden Rules
            // setGrData({
            //   labels: GoldenRulesData.map((d) => d.description),
            //   datasets: [
            //     {
            //       label: "Golden Rules",
            //       data: GoldenRulesData.map((d) => d.count),
            //       borderRadius: 10,
            //       backgroundColor: [
            //         "rgba(255, 99, 132, 0.5)",
            //         "rgba(255, 159, 64, 0.5)",
            //         "rgba(255, 205, 86, 0.5)",
            //         "rgba(75, 192, 192, 0.5)",
            //         "rgba(54, 162, 235, 0.5)",
            //         "rgba(183, 102, 255, 0.5)",
            //         "rgba(101, 250, 207, 0.5)",
            //         "rgba(97, 182, 237, 0.5)",
            //         "rgba(163, 101, 250, 0.5)",
            //         "rgba(211, 50, 204, 0.5)",
            //       ],
            //     },
            //   ],
            // });

            // //By Critical Controls
            // setCcData({
            //   labels: CriticalControlData.map((d) => d.directControl),
            //   datasets: [
            //     {
            //       label: "Critical Controls",
            //       data: CriticalControlData.map((d) => d.count),
            //       borderRadius: 10,
            //     },
            //   ],
            // });
            setIsLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    };
    const getScByCategory = async () => {
      setIsLoading(true);
      await axios.get(`/api/sc/bycategory/${token.projectId}`).then((data) => {
        // console.log(data.data);
        //Golden Rules
        setGrData({
          labels: data.data.bycategory.map((d) => d.description),
          datasets: [
            {
              label: "Golden Rules",
              data: data.data.bycategory.map((d) => d.count),
              borderRadius: 10,
              backgroundColor: [
                "rgba(255, 99, 132, 0.5)",
                "rgba(255, 159, 64, 0.5)",
                "rgba(255, 205, 86, 0.5)",
                "rgba(75, 192, 192, 0.5)",
                "rgba(54, 162, 235, 0.5)",
                "rgba(183, 102, 255, 0.5)",
                "rgba(101, 250, 207, 0.5)",
                "rgba(97, 182, 237, 0.5)",
                "rgba(163, 101, 250, 0.5)",
                "rgba(211, 50, 204, 0.5)",
              ],
            },
          ],
        });
        setIsLoading(false);
      });
    };

    const getScByControl = async () => {
      setIsLoading(true);
      await axios.get(`/api/sc/bycontrol/${token.projectId}`).then((data) => {
        // console.log(data.data);

        //By Critical Controls
        setCcData({
          labels: data.data.bycontrol.map((d) => d.description),
          datasets: [
            {
              label: "Critical Controls",
              data: data.data.bycontrol.map((d) => d.count),
              borderRadius: 10,
            },
          ],
        });
        setIsLoading(false);
      });
    };

    const getScByMonth = async () => {
      setIsLoading(true);
      await axios.get(`/api/sc/bymonth/${token.projectId}`).then((data) => {
        // console.log(data.data);

        //By Month
        setScMonth({
          labels: data.data.bymonth.map((d) => d.description),
          datasets: [
            {
              label: "START Cards",
              data: data.data.bymonth.map((d) => d.count),
            },
          ],
        });
      });
      setIsLoading(false);
    };

    const getScList = async () => {
      setIsLoading(true);
      await axios
        .get(`/api/sc/getallstartcards/${token.projectId}`)
        .then((sc) => {
          // console.log("sc: ", sc);
          // setStartCards(sc.data);
          // setFilteredsc(sc.data);
          // setIsLoading(false);
          const today = new Date();
          const filtered = sc.data.filter((sc) => {
            return (
              moment(sc.workDate).format("YYYY-MM-DD").trim() >=
                moment(today).format("YYYY-MM-DD").trim() &&
              moment(sc.workDate).format("YYYY-MM-DD").trim() <=
                moment(today).format("YYYY-MM-DD").trim()
            );
          });

          setData(filtered);
          setDaysData(filtered);
          setIsLoading(false);
        });
    };

    socket.on("creatingcard", (data) => {
      setStatusMsg("someone creating a START card...");
    });
    socket.on("submittedcard", (data) => {
      // setStatusMsg("new START card uploaded...");
      // setIsLoading(true);
      setStatusMsg("");
      getStartCards();
      getScByCategory();
      getScByControl();
      getScByMonth();
      getScList(); //added: 22-Nov-2024
      // setIsLoading(false);
    });
    // setIsLoading(true);

    getStartCards(); //This is the right way to call a useEffect function --> React gives a warning if this is not the case
    getScByCategory(); //added: 28-Oct-2024
    getScByControl(); //added: 28-Oct-2024
    getScByMonth(); //added: 28-Oct-2024
    getScList(); //added: 22-Nov-2024

    // updateProjectId(); //temp only --> removed after updates: 09-10-2024
    // setIsLoading(false);
  }, [socket]);

  const handleClick = (idx) => {
    setShowList(!showList);
    // console.log("idx: ", idx);
    setData(scByLocation[idx].data);
    // console.log(scByLocation[idx].data);
    setSelected(idx);
  };
  const handleShowList = async () => {
    setSelected(!selected);
    setShowList(!showList);
    setData(daysdata);
  };
  return (
    <>
      <ResponsiveAppBar />

      <Box>
        <Typography
          variant="h4"
          sx={{ marginTop: 2, textAlign: "center", fontWeight: "bold" }}
        >
          dashboard
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
          {moment().format("DD-MMM-YYYY")}
        </Typography>
        {isLoading && <Loading isLoading={isLoading} message="Loading..." />}
        {message && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: 2,
              }}
            >
              <Card
                raised={true}
                sx={{ backgroundColor: selected ? "blue" : "white" }}
              >
                <Button
                  sx={{
                    py: 1,
                    px: 4,
                  }}
                  // variant={selected ? "contained" : ""}
                  onClick={handleShowList}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: selected ? "white" : "blue",
                    }}
                  >
                    {message}
                  </Typography>
                </Button>
              </Card>

              {/* <Button
                sx={{ margin: 1 }}
                // onClick={handleClick}
              >
                <FilterListIcon sx={{ mr: 1 }} />
                <Typography>Filter</Typography>
              </Button> */}
            </Box>
            <Typography sx={{ textAlign: "center", fontSize: 12 }}>
              total START cards submitted today
            </Typography>
          </>
        )}
        <Typography
          className="blink"
          sx={{ textAlign: "center", marginTop: 2, color: "red" }}
        >
          {statusMsg}
        </Typography>

        <Stack
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: 2,
          }}
          direction={{ sm: "column", md: "row" }}
        >
          {scByLocation.length > 0 &&
            scByLocation.map((item, i) => {
              return (
                <Button
                  onClick={() => handleClick(i)}
                  size="small"
                  variant={selected === i ? "contained" : "outlined"}
                  sx={{
                    margin: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  fullWidth
                  key={i}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    {item.location}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      margin: 1,
                      fontWeight: "bold",
                      color: `${selected === i ? "white" : "black"}`,
                    }}
                  >
                    {item.count}
                  </Typography>
                </Button>
              );
            })}
        </Stack>
        <Grid item>{showList && data && <ListStartCard data={data} />}</Grid>
        <Grid container>
          {!isEmpty(dataset) && dataset.labels.length > 0 && (
            <Grid item md={6} xs={12}>
              <PieChartByLocation data={dataset} />
            </Grid>
          )}
          {!isEmpty(grdata) && grdata.labels.length > 0 && (
            <Grid item md={6} xs={12}>
              <BarChartByGoldenRules data={grdata} />
            </Grid>
          )}
        </Grid>
        <Grid container>
          {!isEmpty(ccdata) && ccdata.labels.length > 0 && (
            <Grid item md={6} xs={12}>
              <BarChartByControls data={ccdata} />
            </Grid>
          )}
          {!isEmpty(scmonth) && scmonth.labels.length > 0 && (
            <Grid item md={6} xs={12}>
              {/* <LineChartByMonth
              startcards={{
                labels: Data.map((d) => d.month),
                datasets: [
                  {
                    label: "startcards",
                    data: Data.map((d) => d.count),
                  },
                ],
              }}
            /> */}

              <LineChartByMonth data={scmonth} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
