import React, { useState } from "react";

import axios from "axios";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

import { useForm } from "react-hook-form";

function UpdateCondition({
  setUpdateConditions,
  setStartCard,
  startcard,
  setIsLoading,
  setShowConditions,
}) {
  //react-hook-form values
  useForm({
    defaultValues: {
      conditions: "",
      management: "",
    },
  });
  // const { handleSubmit, control, setValue, getValues, formState, register }
  // const { errors } = formState;

  const { handleSubmit, register } = useForm();

  //state variables here
  // const [sign, setSign] = useState();
  // const [url, setUrl] = useState();
  const [open, setOpen] = useState(true);

  const onSubmit = async (d) => {
    console.log("data: ", d);
    console.log("startcard: ", startcard);

    startcard.conditions = d.conditions;
    startcard.management = d.management;
    setStartCard(startcard);
    setOpen(false);

    setIsLoading(true);

    await axios
      .patch("/api/sc/scconditionsupdate", startcard)
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setUpdateConditions(true);
        setShowConditions(false);
      }); //call the backend
  };
  const handleClear = () => {
    setOpen(false);
    setUpdateConditions(false);
    setIsLoading(false);
    setShowConditions(false);
  };
  //Note: onSubmit method of the <form> will work only with the <Dialog> component if it is inside it. --> 20-Oct-2024
  return (
    <Stack>
      <Dialog open={open} fullWidth>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
            {"Update Conditions and Management"}
          </DialogTitle>
          <DialogContent sx={{ margin: 1, padding: 1 }}>
            <TextField
              name="conditions"
              label="Conditions"
              {...register("conditions")}
              fullWidth
            />
          </DialogContent>
          <DialogContent sx={{ margin: 1, padding: 1 }}>
            <TextField
              name="management"
              label="Management"
              {...register("management")}
              fullWidth
            />
          </DialogContent>

          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: "end", margin: 2 }}
          >
            <Button
              type="submit"
              variant="contained"
              color="error"
              // onClick={handleGenerate}
              autoFocus
            >
              Save
            </Button>
            <Button variant="outlined" color="primary" onClick={handleClear}>
              Cancel
            </Button>
          </Stack>
        </form>
      </Dialog>
    </Stack>
  );
}
export default UpdateCondition;
