import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AttendeesContextProvider } from "./context/AttendeesContext";
import { EmployersContextProvider } from "./context/EmployersContext";
import { LoginContextProvider } from "./context/LoginContext";
import { StartCardsContextProvider } from "./context/StartCardsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StartCardsContextProvider>
    <EmployersContextProvider>
      <AttendeesContextProvider>
        <LoginContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LoginContextProvider>
      </AttendeesContextProvider>
    </EmployersContextProvider>
  </StartCardsContextProvider>
);

// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import { BrowserRouter } from "react-router-dom";
// import { AuthContextProvider } from "./context/AuthContext";
// import { ObservationContextProvider } from "./context/ObservationContext";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <ObservationContextProvider>
//     <AuthContextProvider>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </AuthContextProvider>
//   </ObservationContextProvider>
// );
