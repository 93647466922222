import { useContext } from "react";
import { EmployersContext } from "../context/EmployersContext";

export const useEmployersContext = () => {
  const context = useContext(EmployersContext);

  if (!context) {
    throw Error("Context must be used inside a ContextProvider");
  }

  return context;
};
