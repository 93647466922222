import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import moment from "moment";
// import Category from "./Category";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// <Typography sx={{ textAlign: "center", padding: 1 }}>
//   <a href={"https://" + value} style={{ textDecoration: "none" }}>
//     {"https://" + value}
//   </a>
// </Typography>;

export default function ListStartCard({ data }) {
  // console.log("data: ", data);
  return (
    <>
      <Container maxWidth="xxl">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Location</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Task</StyledTableCell>
                <StyledTableCell>Golden Rule</StyledTableCell>
                <StyledTableCell>URL</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell component="th" scope="row">
                    {row.location}
                  </StyledTableCell>
                  <StyledTableCell>{row.employerName}</StyledTableCell>
                  <StyledTableCell>
                    {moment(row.workDate).format("DD-MMM-YYYY HH:MM A")}
                  </StyledTableCell>
                  <StyledTableCell>{row.task}</StyledTableCell>
                  <StyledTableCell>
                    <ul>
                      {row.categories.map((c, idx) => {
                        return <li key={idx}>{c.category}</li>;
                      })}
                    </ul>
                  </StyledTableCell>
                  <StyledTableCell>
                    <a
                      href={
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/getsc/" +
                        row._id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {`${window.location.protocol}//${window.location.host}/getsc/${row._id}`}
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
