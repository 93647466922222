import { createContext, useReducer } from "react";

export const EmployersContext = createContext();

export const employersReducer = (state, action) => {
  switch (action.type) {
    case "SET_EMPLOYERS":
      return {
        employers: action.payload,
      };

    case "UPDATE_EMPLOYERS":
      return {
        employers: [...state.employers],
      };

    default:
      return state;
  }
};

export const EmployersContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(employersReducer, {
    employers: null,
  });

  return (
    <EmployersContext.Provider value={{ ...state, dispatch }}>
      {children}
    </EmployersContext.Provider>
  );
};
