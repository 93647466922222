import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";

const ShowGoldenRule = ({ goldenRule }) => {
  return (
    <>
      {goldenRule?.map((g, idx) => {
        return (
          <Grid item md={12 / goldenRule.length} xs={12} key={idx}>
            <Button
              sx={{
                padding: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
              variant="outlined"
              fullWidth
            >
              <img
                src={`../${g.imgSrc}`}
                width="100px"
                height="100px"
                alt="golden rule icon"
              />
              <Typography
                sx={{ margin: 2, color: "black", fontWeight: "bold" }}
              >
                {g.category}
              </Typography>
            </Button>
          </Grid>
        );
      })}
    </>
  );
};

export default ShowGoldenRule;
