import { useState, useEffect, useContext } from "react";
import axios from "axios";

import QRCode from "react-qr-code";
import generatePDF, { Margin } from "react-to-pdf";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import ResponsiveAppBar from "./ResponsiveAppBar";
import { FormInputDropdownSetup } from "./form-components/FormInputDropdownSetup";

import { useLoginContext } from "../hooks/useLoginContext";

//Create QR Code component
const CreateQRCode = ({ url, projectName }) => {
  return (
    <>
      <Container>
        <div id="content-id" style={{ color: "black", marginTop: "50px" }}>
          <Container sx={{ mt: 5 }}>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: "bold", padding: 5 }}
            >
              digital START card
            </Typography>
            <Typography sx={{ textAlign: "center" }}>{projectName}</Typography>
            <Typography sx={{ textAlign: "center", padding: 1 }}>
              {url && (
                <a
                  href={url}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  {url}
                </a>
              )}
            </Typography>

            <div
              style={{
                height: "auto",
                margin: "20px auto",
                maxWidth: 200,
                width: "100%",
              }}
            >
              <QRCode
                size={500}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={url}
                viewBox={`0 0 500 500`}
              />
            </div>
          </Container>
        </div>
        <GeneratePDF />
      </Container>
    </>
  );
};

const options = {
  // default is `save`
  method: "save",

  // resolution: Resolution.MEDIUM,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: "A4",
    // default is 'portrait'
    orientation: "portrait",
  },
  // canvas: {
  //   // default is 'image/jpeg' for better size performance
  //   // mimeType: "image/png",
  //   mimeType: "image/jpg",
  //   qualityRatio: 1,
  // },
};

// you can use a function to return the target element besides using React refs
const getTargetElement = () => document.getElementById("content-id");

const GeneratePDF = () => {
  return (
    <>
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => generatePDF(getTargetElement, options)}
          sx={{ marginTop: 3, marginBottom: 3, mx: "auto" }}
        >
          Generate PDF
        </Button>
      </Container>
    </>
  );
};

export default function GenerateProjectQRCode() {
  const { token } = useLoginContext();

  const form = useForm({
    defaultValues: {
      projectId: "",
    },
  });
  const { register, handleSubmit, formState, setError, control, getValues } =
    form;
  const { errors } = formState;
  const [open, setOpen] = useState(true);
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [url, setUrl] = useState(""); //URL created after submission

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xxl"));

  const navigate = useNavigate();

  useEffect(() => {
    const getProjects = async () => {
      const projects = await axios.get("/api/sc/getprojects");
      // console.log("Projects: ", projects.data);
      // localStorage.setItem("projects", JSON.stringify(projects.data));

      try {
        const projectsData = projects.data[0].map((project) => {
          return {
            value: project.projectId,
            label: project.name,
          };
        });
        //sort projects by name
        projectsData.sort((a, b) => {
          let fa = a.label.toLowerCase(),
            fb = b.label.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });

        setProjects(projectsData);
      } catch (err) {
        console.log(err);
      }
    };
    getProjects();
  }, [url, projectId, projectName]);

  const onSubmit = async (data) => {
    console.log("data: ", data);
    // setUrl(`https://startcard.safenode.co.nz/${data.projectId}`);
    setUrl(`${window.location.host}/${data.projectId}`);
  };

  const handleClose = () => {
    navigate("/dashboard", { replace: true });
    setOpen(false);
  };

  return (
    <div>
      <ResponsiveAppBar />
      {projects && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={fullScreen}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Typography
              sx={{ fontWeight: "bold", textAlign: "center" }}
              variant="h4"
            >
              generate project QR code
            </Typography>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <DialogContent sx={{ margin: 2, padding: 1 }}>
              <FormInputDropdownSetup
                name="projectId"
                control={control}
                label="Project"
                options={projects}
                {...register("projectId", { required: true })}
                getValues={getValues}
                setProjectId={setProjectId}
                setProjectName={setProjectName}
                setUrl={setUrl}
              />
              {errors.projectId && (
                <Typography variant="caption" sx={{ color: "red" }}>
                  Select Project
                </Typography>
              )}
            </DialogContent>

            {projectId && projectName && (
              <CreateQRCode
                // url={`https://startcard.safenode.co.nz/${projectId}`}
                url={url}
                projectName={projectName}
              />
            )}
            <DialogContent sx={{ margin: 2, padding: 1 }}></DialogContent>
          </form>
        </Dialog>
      )}
    </div>
  );
}
